import WebsiteConfiguration from '../../../configurations/WebsiteConfiguration';
import Batch from '../../../../shared/entities/Batch';
import ChequeBatch from "../../../../shared/entities/ChequeBatch";

class BatchingService {

    async getBatchList(offset: number, limit: number): Promise<{items:Batch[], totalLength: number}> {
        return await WebsiteConfiguration.current.apiTransport.list<Batch>(
            "batches/?offset=" + offset + "&limit=" + limit, {});
    }

    async getChequeBatchList(offset: number, limit: number): Promise<{items:ChequeBatch[], totalLength: number}> {
        return await WebsiteConfiguration.current.apiTransport.list<ChequeBatch>(
            "cheque-batches/?offset=" + offset + "&limit=" + limit, {});
    }

    async getBatchCsv(batchNumber: number): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("batches/" + batchNumber, {});
    }

    async getChequeCsv(id: number): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("cheque-batches/" + id, {});
    }

    async getPaymentsCsv(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("batched-payments/", {});
    }

    async getChequePaymentsCsv(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("batched-cheques/", {});
    }

    async createNewBatch(batch: ChequeBatch): Promise<ChequeBatch> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("new-batch/create", {batch});
    }

    async closeBatch(batch: ChequeBatch): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("new-batch/close", {batch});
    }

    async getBatchDetails(id: number) : Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("chequebatch-details/" + id, {})
    }

}

export default BatchingService;