import React, { useContext, useEffect, useState } from "react";
import PayDemand from "../payment/PayDemand";
import PayRent from "../rent/PayRent";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import BatchList from "../batching/BatchList";
import ChequeBatchList from "../batching/ChequeBatchList";
import AuthenticatedRoute from "../core/components/routes/AuthenticatedRoute";
import LoginContext from "../core/contexts/LoginContext";
import Login from "./Login";
import Menu from "./Menu";
import SendEDemands from "../edemands/SendEDemands";
import Receipts from "../receipts/Receipts";
import Demands from "../edemands/Demands";
import ClientContext from "../core/contexts/ClientContext";
import FindPaymentsUseCase from "../../../api/src/payment/use-cases/FindPaymentsUseCase";
import NewBatch from "../batching/NewBatch";
import EditBatch from "../batching/EditBatch";
import ChequeBatchManagement from "../batching/ChequeBatchManagement";
import { useAsyncAction } from "../core/hooks/useAsyncAction";
import AuthService from "./services/AuthService";
import BlockedAccounts from "./blocked/BlockedAccounts";

const Admin = () => {
  const loginState = useContext(LoginContext);
  const confirmingLoginAction = useAsyncAction();

  const client = useContext(ClientContext);

  useEffect(() => {
    confirmingLoginAction.trigger(async () => {
      try {
        // We simply need to call checkLogin. If checking the current login token fails,
        // the underlying Axios transport will "log us out" and loginState will register
        // that we are no longer logged in.
        new AuthService().checkLogin();
      } catch (error) {}
    });
  }, []);

  return (
    <>
      {confirmingLoginAction.state.slow && <p>Checking Login Details</p>}
      {confirmingLoginAction.state.complete && (
        <Switch>
          <AuthenticatedRoute path="/admin/edemands" roles={["edemands"]}>
            <ClientContext.Consumer>
              {(client) => <SendEDemands client={client} />}
            </ClientContext.Consumer>
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/moto">
            <PayDemand moto={true} />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/rent">
            <PayRent moto={true} />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/batches" roles={["batching"]}>
            <BatchList />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/cheque-batches" roles={["cheques"]}>
            <ChequeBatchManagement />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/new-batch" roles={["batching"]}>
            <NewBatch />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/admin/edit-batch/" roles={["batching"]}>
            <EditBatch />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/admin/receipts">
            <Receipts />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path="/admin/demands"
            roles={["search-demands"]}
          >
            <Demands />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path="/admin/blocked"
            roles={["blocked-accounts"]}
          >
            <BlockedAccounts />
          </AuthenticatedRoute>          
          <Route exact path="/admin">
            {loginState.isAuthenticated() ? <Menu /> : <Login />}
          </Route>
        </Switch>
      )}
    </>
  );
};

export default Admin;
