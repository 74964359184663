import WebsiteConfiguration from '../../../configurations/WebsiteConfiguration';
import {IPayment} from "../../core/components/receipts/IPayment";

class ReceiptService {

    async getPayment(searchTerm: string): Promise<IPayment[]> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("payments", {searchTerm});
    }

    async updatePaymentEmail(id: number, email: string): Promise<void> {
        await WebsiteConfiguration.current.apiTransport.post<any>("payments/" + id + "/receipts", {email});
    }

}

export default ReceiptService;