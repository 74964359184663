import React, { useState } from "react";
import { Border, GridLayout, Button } from "morse-css";
import IBlockedAccount from "../../../../shared/entities/IBlockedAccount";
import useBlockedAccounts from "./hooks/useBlockedAccounts";

const UpdateBlockedAccount = (props: {
  account: IBlockedAccount;
  searchBlockedAccounts: () => void;
  confirmUpdate: () => void;
}) => {
  const showError = false;
  const blockAccounts = useBlockedAccounts();

  const blockAccount = async () => {
    if (confirm("Are you sure you want to block this account?")) {
      await blockAccounts.blockAccount(props.account);
      props.confirmUpdate();
    }
  };
  const unBlockAccount = async () => {
    if (confirm("Are you sure you want to unblock this account?")) {
      blockAccounts.unBlockAccount(props.account);
      await props.confirmUpdate();
    }
  };

  return (
    <div>
      <h2 className="c-heading +h2 u-marg-bottom">Update Blocking Details</h2>
      <GridLayout columns={2}>
        <GridLayout.Item>
          <div>
            {props.account.demand && (
              <GridLayout columns={2}>
                <GridLayout.Item>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Name:</label>
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Email:</label>
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Phone Number:</label>
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Blocked Status:</label>
                  </div>
                </GridLayout.Item>
                <GridLayout.Item>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    {props.account.demand?.name}
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    {props.account.demand?.email}
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    {props.account.demand?.phoneNumber}
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    {props.account &&
                      (props.account.isBlocked ? (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          Blocked
                        </span>
                      ) : (
                        <span>Not Blocked</span>
                      ))}
                  </div>
                </GridLayout.Item>
              </GridLayout>
            )}
            {!props.account.demand && (
              <p>
                There is no demand for this tenant reference. You may continue
                to block payments regardless should any demands be added at a
                later date.
              </p>
            )}
            <GridLayout columns={3} className="u-marg-top-large">
              <GridLayout.Item>
                <div>
                  <Button onClick={props.searchBlockedAccounts}>
                    Return to Search
                  </Button>
                </div>
              </GridLayout.Item>
              <GridLayout.Item>
                <div>
                  {!props.account.isBlocked && (
                    <Button className="c-button +1" onClick={blockAccount}>
                      Block Payment
                    </Button>
                  )}

                  {props.account.isBlocked && (
                    <Button className="c-button +1" onClick={unBlockAccount}>
                      Allow Payment
                    </Button>
                  )}
                </div>
              </GridLayout.Item>
              <GridLayout.Item>
                <div>
                  {showError && (
                    <div
                      className="c-alert u-fill-neg u-bold u-justify-content-center"
                      style={{ width: "250px" }}
                    >
                      Server could not be updated. Please try again in a few
                      mins
                    </div>
                  )}
                </div>
              </GridLayout.Item>
            </GridLayout>
          </div>
        </GridLayout.Item>
        <GridLayout.Item border={Border.Left}>
          <div className="l-wrap-box u-width-2">
            <div className="u-font-small u-pad u-pad--heavy@m">
              <h2 className="c-heading +h3">Demand Details:</h2>
              <ul className="u-list-dash">
                <li>
                  <h2 className="c-heading +h4">Tenant Ref:</h2>{" "}
                  {props.account.tenantRef}
                </li>
                {props.account.demand && (
                  <>
                    <li>
                      <h2 className="c-heading +h4">Property Address:</h2>{" "}
                      {props.account.demand?.propertyAddress}
                    </li>
                    <li>
                      <h2 className="c-heading +h4">Invoice Number:</h2>{" "}
                      {props.account.demand?.invoiceNumber}
                    </li>
                    <li>
                      <h2 className="c-heading +h4">Current Balance:</h2> £
                      {props.account.demand?.groundRentToPay?.toFixed(2)}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </GridLayout.Item>
      </GridLayout>
    </div>
  );
};

export default UpdateBlockedAccount;
