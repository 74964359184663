import {Column, Entity, Index, PrimaryColumn, PrimaryGeneratedColumn} from "typeorm";

@Entity()
export default class Demand {
    @PrimaryGeneratedColumn()
    id?: number;

    @Column({default: ""})
    public name: string = '';

    @Column({default: ""})
    @Index()
    public tenantRef: string = '';

    @Column({default: ""})
    public invoiceNumber: string = '';

    @Column({default: ""})
    public code: string = '';

    @Column({default: ""})
    public phoneNumber?: string = '';

    @Column({default: ""})
    public email?: string = '';

    @Column({default: ""})
    public propertyAddress?: string = '';

    @Column({default: ""})
    public paymentType?: string = '';

    @Column({type: "boolean", default: false})
    public electronicDemands: boolean = false;

    @Column({type: "decimal", default: 0, precision: 8, scale: 2})
    public groundRentToPay: number = 0;

    @Column({type: "decimal", precision: 8, scale: 2})
    public groundRentInPeriod: number = 0;

    @Column({type: "decimal", precision: 8, scale: 2})
    public groundRentBroughtForward: number = 0;

    @Column({type: "integer", default: 0})
    public eDemandQueueItemId?: number = 0;

    @Column({default: "", length: 150})
    public arrearsT1: string = '';

    @Column({default: "", length: 150})
    public arrearsT2: string = '';

    @Column({default: "", length: 150})
    public arrearsT3: string = '';

    @Column({default: "", length: 150})
    public arrearsT4: string = '';

    @Column({default: "", length: 150})
    public arrearsT5: string = '';

    @Column({type: "datetime", nullable: true})
    public modifiedDate?: Date;

    @Column({default: ""})
    public modifiedUsername?: string = '';

    @Column({type: "boolean", default: false})
    public holdEmail?: boolean = false;

    // Not a DB column
    isBlocked: boolean = false;

    @Column({nullable: true})
    public preparationDate?: string;

    @Column({nullable: true})
    public demandDate?: string;
}