import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Payment, { PaymentStatus } from "../../../../shared/entities/Payment";
import RentService from "../services/RentService";
import RentDemand from "../../../../shared/entities/RentDemand";
import DemandService from "../../payment/services/DemandService";
import { useAsyncAction } from "../../core/hooks/useAsyncAction";

const PaymentStep = (props: any & { demand: RentDemand }) => {
  const [payment, setPayment] = useState<boolean | Payment>(false);

  useEffect(() => {
    new RentService().startPayment(props.demand).then((payment) => {
      setPayment(payment);
    });
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const payNowAction = useAsyncAction();

  const onPaymentSubmitted = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    payNowAction.trigger(async () => {
      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        let intent = "";
        if ((payment as Payment).paymentIntent) {
          intent = "" + (payment as Payment).paymentIntent;
        }

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        if (error) {
          alert(
            "Sorry, the payment failed with the following error message: " +
              error.message
          );

          // Show error to your customer (e.g., insufficient funds)
          (payment as Payment).status = PaymentStatus.Failed;
          (payment as Payment).paymentError = error.message
            ? error.message
            : "";

          await new RentService().updatePayment(payment as Payment);
        } else {
          if (paymentMethod) {
            const updatedPayment = await new DemandService().confirmPayment(
              payment as Payment,
              paymentMethod.id
            );

            if (updatedPayment.status === PaymentStatus.Success) {
              props.changeStep && props.changeStep("PaymentCompleteStep");
              props.setPaymentId && props.setPaymentId((payment as Payment).id);
            } else {
              alert(
                "Sorry the payment was not successful: " +
                  updatedPayment.paymentError
              );
            }
          } else {
            alert("Sorry the payment was not successful");
          }
        }
      }
    });
  };

  return (
    <form onSubmit={onPaymentSubmitted}>
      <div className="l-wrap u-width-4">
        <div className="cc-module u-marg-bottom">
          <div className="cc-module__header">
            <h3 className="o-flex__item u-white">Payment</h3>
          </div>
          <div className="cc-module__body">
            <p className="u-marg-bottom">
              Card details are processed using our secure payment provider,
              Stripe Payments.
            </p>
            {payment && (
              <>
                <label className="c-label">Card details</label>
                <CardElement options={cardElementOptions} />
              </>
            )}
            {!payment && <p>Loading payment details...</p>}
          </div>
        </div>
        {payment && (
          <button
            className="c-button +1 +large"
            disabled={payNowAction.state.triggered}
          >
            Pay Now
          </button>
        )}
      </div>
    </form>
  );
};

export default PaymentStep;
