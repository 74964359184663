import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DemandWithTotalPaid } from "../../../../shared/types/DemandWithTotalPaid";
import DemandContext from "../contexts/DemandContext";
import DemandService from "../services/DemandService";
import { calculateSurcharge } from "../../../../shared/util/surchargeHelper";
import LoginContext from "../../core/contexts/LoginContext";
import ClientContext from "../../core/contexts/ClientContext";
import Demand from "../../../../shared/entities/Demand";
import BlockedAccountService from "../../admin/services/BlockedAccountService";

const PayeeContactDetailsStep = (
  props: any & { demand: DemandWithTotalPaid }
) => {
  const loginState = useContext(LoginContext);
  const { register, handleSubmit, watch, errors } = useForm<Demand>();
  const [formattedAmount, setFormattedAmount] = useState("");

  const onSubmit = async (data) => {
    if (loginState.user) {
      if (loginState.user.staffRoles.includes("tricord")) {
        if (!confirm("Have you paused call recording?")) {
          return;
        }
      }
    }

    props.submitData && props.submitData(data);

    props.demand.email = data.email;
    props.demand.phoneNumber = data.phoneNumber;
    props.demand.electronicDemands = data.electronicDemands;

    new DemandService().updateDemand(props.demand);

    props.changeStep && props.changeStep("ContinueToPaymentStep");
  };

  const onChangePaymentMethod = (e) => {
    props.submitData({ ...props.submittedData, paymentMethod: e.target.value });
  };

  let surcharge = 0;

  if (props.submittedData && props.submittedData.paymentMethod) {
    surcharge = calculateSurcharge(
      props.amountToPay,
      props.submittedData.paymentMethod
    );
  }

  useEffect(() => {
    if (props.amountToPay > 0 && formattedAmount == "") {
      setFormattedAmount(
        props.amountToPay.toLocaleString("en-UK", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [props.amountToPay]);

  const onAmountToPayInputChanged = (newAmount) => {
    setFormattedAmount(newAmount);
    props.updateAmountToPay(parseFloat(newAmount));
  };

  const total = props.amountToPay + surcharge;
  const eDemandsValue = watch("electronicDemands");

  // Step count is just a visual indicator
  let stepCount = 1;

  const client = useContext(ClientContext);
  const demand = useContext(DemandContext);

  useEffect(() => {
    if (demand.isBlocked) {
      const blockedAccountsService = new BlockedAccountService();
      blockedAccountsService.sendNotification(
        demand.tenantRef,
        "Groundrent Payment"
      );
    }
  }, []);

  if (demand.isBlocked) {
    if (props.moto) {
      return (
        <>
          <div className="c-alert +urgent">
            <p>
              This Tenant has been Blocked from online payment
              <br />
              <br />
              Refer to Qube for Reason e.g. Legal Action
            </p>
          </div>
          <p style={{ textAlign: "center" }}>
            <a href="/admin/moto">
              <span className="c-button +1">Continue</span>
            </a>
          </p>
        </>
      );
    } else {
      return (
        <>
          <div className="c-alert +urgent">
            <p>
              You are currently unable to pay through the online portal.
              <br />
              <br />
              Please contact the Ground Rent Department on{" "}
              {client?.paymentsPhoneNumber} (Option 2) Quoting Error Message
              MCSTLA and they will advise you of alternative payment process.
            </p>
          </div>{" "}
          <p style={{ textAlign: "center" }}>
            <a href="/">
              <span className="c-button +1">Close</span>
            </a>
          </p>
        </>
      );
    }
  }

  if (!props.moto && demand.totalPaid) {
    return (
      <div className="c-alert">
        <p>Sorry, this demand has already been paid.</p>
        <p>
          If you have any concerns or questions please contact our payment line
          on <b>{client?.paymentsPhoneNumber}</b>
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="o-wrap o-wrap--narrow">
        <p className="u-marg-bottom">
          Please check the following information from your latest Ground Rent
          demand:
        </p>

        <div className="cc-module">
          <div className="cc-module__header">
            <h3 className="o-flex__item u-white">
              {stepCount++}. Invoice (Invoice number -{" "}
              {props.demand
                ? props.demand.invoiceNumber
                : props.demand.invoiceNumber}
              )
            </h3>
          </div>
          <div className="cc-module__body">
            {props.moto ? (
              <>
                <p>
                  Original Demand:{" "}
                  <b>
                    &pound;
                    {demand.groundRentToPay.toLocaleString("en-UK", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </p>
                <p>
                  Total Payments:{" "}
                  <b>
                    &pound;
                    {demand.totalPaid.toLocaleString("en-UK", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </b>
                </p>
                <p>
                  Balance to pay: <b>&pound;</b>
                  <input
                    type="number"
                    step="any"
                    name="groundRentToPay"
                    onChange={(e) => {
                      onAmountToPayInputChanged(e.target.value);
                    }}
                    value={formattedAmount}
                    ref={register({ required: true })}
                  ></input>
                </p>
              </>
            ) : (
              <p>
                Balance to pay:{" "}
                <b>
                  &pound;{" "}
                  {props.amountToPay.toLocaleString("en-UK", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </b>
              </p>
            )}
          </div>
        </div>

        <div className="cc-module u-bordered">
          <div className="cc-module__header">
            <h3 className="u-white">
              {stepCount++}. Property (Tenant Ref -{" "}
              {props.demand ? props.demand.tenantRef : props.demand.tenantRef})
            </h3>
          </div>
          <div className="cc-module__body">
            <p>
              Tenancy:{" "}
              <b>
                {props.demand
                  ? props.demand.propertyAddress
                  : props.demand.propertyAddress}
              </b>
            </p>
          </div>
        </div>

        <div className="cc-module u-bordered">
          <div className="cc-module__header">
            <h3 className="u-white">{stepCount++}. Contact Details</h3>
          </div>
          <div className="cc-module__body">
            <div className="c-fields +equal +inline">
              <div className="c-field">
                <label htmlFor="phone" className="c-label">
                  Email Address{!props.moto && <em id="EmailValidation">*</em>}
                </label>
                {props.moto && (
                  <input
                    id="email"
                    type="email"
                    autoFocus
                    placeholder="email@email.com"
                    name="email"
                    className="c-input +text +fill"
                    defaultValue={props.demand.email}
                    ref={register({
                      validate: (value) => {
                        if (eDemandsValue) {
                          return value.trim() != "";
                        }
                      },
                    })}
                  />
                )}
                {!props.moto && (
                  <input
                    id="email"
                    type="email"
                    autoFocus
                    required
                    placeholder="email@email.com"
                    name="email"
                    className="c-input +text +fill"
                    defaultValue={props.demand.email}
                    ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  />
                )}
                <span className="c-field__helper u-font-small u-translucent">
                  (This is required in order to send you a receipt for your
                  payment)
                </span>
              </div>
              <div className="c-field">
                <label htmlFor="phone" className="c-label">
                  Daytime Telephone
                </label>
                <input
                  id="phone"
                  type="phone"
                  placeholder="0281234567"
                  name="phoneNumber"
                  className="c-input +text +fill"
                  defaultValue={props.demand.phoneNumber}
                  ref={register}
                />
                <span className="c-field__helper u-font-small u-translucent">
                  (Only for use in case of problems with Card Payment)
                </span>
              </div>
            </div>
          </div>
        </div>

        {client?.enableEdemands && (
          <div className="cc-module u-bordered">
            <div className="cc-module__header">
              <h3 className="u-white">{stepCount++}. eDemands</h3>
            </div>
            <div className="cc-module__body">
              <p className="u-marg-bottom">
                Please help us process your payment more efficiently in future
                by signing up to electronic demands
              </p>
              <label className="c-label">
                <input
                  id="electronicDemands"
                  type="checkbox"
                  name="electronicDemands"
                  defaultChecked={props.demand.electronicDemands}
                  ref={register}
                />
                I agree to receiving electronic demands in future by email to
                the address specified above.
              </label>
            </div>
          </div>
        )}

        {client?.enableSurcharges && (
          <div className="cc-module u-bordered">
            <div className="cc-module__header">
              <h3 className="u-white">{stepCount++}. Payment</h3>
            </div>
            <div className="cc-module__body">
              <table
                style={{ width: "300px" }}
                className="c-table +grid u-marg-bottom"
              >
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="phone" className="c-label">
                        Payment Method: <em id="CardTypeValidation">*</em>
                      </label>
                    </td>
                    <td className="u-align-right">
                      <select
                        className="c-input +tight +no-border +select"
                        required
                        id="paymentMethod"
                        name="paymentMethod"
                        ref={register({ required: true })}
                        onChange={onChangePaymentMethod}
                      >
                        <option value="">Please Select</option>
                        <option>Personal Card</option>
                        <option>Business Card</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Demand Total:</td>
                    <td style={{ textAlign: "right" }}>
                      &pound;
                      {props.amountToPay.toLocaleString("en-UK", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Surcharge:</td>
                    <td style={{ textAlign: "right" }}>
                      &pound;
                      <span id="surcharge">
                        {surcharge.toLocaleString("en-UK", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </td>
                  </tr>
                  <tr className="u-bold">
                    <td>Payment Total:</td>
                    <td style={{ textAlign: "right" }}>
                      &pound;
                      <span id="total">
                        {total.toLocaleString("en-UK", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="u-font-small">
                Please note:
                <br />A transaction charge of <b>2.5%</b> will be made for
                Business Card payments. No transaction charge will made for
                Personal Card payments
              </p>
            </div>
          </div>
        )}

        <button type="submit" className="c-button +large +1">
          Continue
        </button>
      </div>
    </form>
  );
};

export default PayeeContactDetailsStep;
