import React, { useState, useEffect, useRef } from "react";
import { AppLayout, Border, GridLayout, Button } from "morse-css";
import { useForm } from "react-hook-form";
import BatchTable from "./Cheques/BatchTable";
import ChequeBatch, { BatchStatus } from "../../../shared/entities/ChequeBatch";
import Payment from "../../../shared/entities/Payment";
import useDebounce from "../receipts/hooks/useDebounce";
import useDemand from "../payment/hooks/useDemand";
import { IDemand } from "../../../shared/entities/IDemand";
import BatchingService from "./services/BatchingService";
import DemandService from "../payment/services/DemandService";
import dayjs from "dayjs";
import { CARD_BATCH_MANAGEMENT_STEP } from "./ChequeBatchManagement";
import useBlockedAccounts from "../admin/blocked/hooks/useBlockedAccounts";
import BlockedAccountService from "../admin/services/BlockedAccountService";

const EditBatch = (props: any) => {
  const { register, handleSubmit, reset } = useForm<Payment>();
  const tenantRef = useRef<HTMLInputElement>();
  const [batchTotal, setBatchTotal] = useState<number>(0);
  const [error, setShowError] = useState<string>("");
  const { getDemandByTenantRef } = useDemand();
  const blockedAccounts = useBlockedAccounts();
  const [demand, setDemand] = useState<IDemand>();
  const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [showResults, setShowResults] = useState(false);

  const displayBatchDate = props.batch.batchDate;
  const displayBatchNumber = props.batch.batchNumber;
  const displayBatchOpenedBy = props.batch.username;
  const displayBatchOpenDate = props.batch.openedDate;

  useEffect(() => {
    if (props.payments) {
      setItems(props.payments);
    }
  }, []);

  useEffect(() => {
    let tempBatchTotal = 0;
    items.forEach((item) => {
      tempBatchTotal = tempBatchTotal + parseFloat(item.amount);
    });
    setBatchTotal(tempBatchTotal);
  }, [items]);

  const checkBlocked = async (value: string) => {
    if (!value) {
      return;
    }

    const blockedDetails = await blockedAccounts.getAccountDetails(value);

    if (blockedDetails.isBlocked) {
      alert(
        "This Tenant has been Blocked from online payment - Refer to Qube for Reason e.g. Legal Action"
      );
      const blockedAccountsService = new BlockedAccountService();
      blockedAccountsService.sendNotification(value, "Cheque Payment");
      reset();
      tenantRef.current?.focus?.();
    }
  };

  /* Searches for Tenant to get details */
  const updateSearchTerm = useDebounce(async (value: string) => {
    blockedAccounts.clear();
    setLoadingFlag(true);
    setShowError("");
    if (value.length > 0) {
      const result = await getDemandByTenantRef(value);
      if (result) {
        setDemand(result);
        setShowResults(true);
      } else {
        setDemand(undefined);
        setShowResults(false);
        setShowError("No tenancy matching `'" + value + "`'");
      }
    } else {
      setDemand(undefined);
      setShowError("");
    }

    await blockedAccounts.getAccountDetails(value);

    setLoadingFlag(false);
  }, 500);

  const removeCheque = async (chequeitem) => {
    const confirmation = confirm(
      "Are you sure you wish to delete this payment?"
    );
    if (confirmation) {
      let filteredArray = items.filter((item) => item !== chequeitem);
      setItems(filteredArray);
      setBatchTotal(batchTotal - parseFloat(chequeitem.paid));
      await new DemandService().deletePayment(chequeitem);
    }
  };

  const addCheque = async (chequePayment) => {
    const payment = await new DemandService().makeSingleChequePayment(
      chequePayment
    );
    setItems([payment, ...items]);
  };

  const addToBatch = async (data) => {
    // await new DemandService().makeChequePayment(chequePayments);
    props.changeStep(CARD_BATCH_MANAGEMENT_STEP);
  };

  /* Form submit function that creates a new payment in tblPayment, also pushes the demand information to table on modal, adds total on modal & resets form */
  const formSubmit = async (data) => {
    if (demand && demand.groundRentToPay) {
      const chequePayment: Payment = {
        demandId: demand.id,
        createdDate: data.date,
        chargeDate: data.date,
        username: "",
        amount: data.amount,
        chequeNumber: data.chequeNumber,
        accountName: data.accountName,
        accountNumber: data.accountNumber,
        sortCode: data.sortCode,
        notes: data.notes,
        paymentMethod: "Cheque",
        electronicDemands: false,
        invoiceNumber: demand.invoiceNumber,
        tenantRef: demand.tenantRef,
        propertyAddress: demand.propertyAddress,
        due: demand.groundRentToPay,
        balance: demand.groundRentToPay - data.amount,
        total: data.amount,
        paymentIntent: "",
        paymentError: "",
        email: "",
        batchNumber: "",
        batchId: props.batch.id,
        statusChecked: false,
      };
      const item = {
        id: demand.id,
        tenantRef: demand.tenantRef,
        invoiceNumber: demand.invoiceNumber,
        propertyAddress: demand.propertyAddress,
        due: demand.groundRentToPay,
        amount: data.amount,
        chequeNumber: data.chequeNumber,
      };

      addCheque(chequePayment);
    }
    setShowResults(false);
    reset({
      tenantRef: "",
      amount: parseInt(""),
      chequeNumber: "",
      accountName: "",
      accountNumber: "",
      sortCode: "",
      notes: "",
    });
  };

  const Results = () => (
    <GridLayout.Item border={Border.Left}>
      <div className="l-wrap-box u-width-2 u-marg">
        <div className="u-font-small">
          <ul className="u-list-dash">
            <li>
              <p id="propertyAddress">Property Address:</p>
              <b>{demand ? demand.propertyAddress : ""}</b>
            </li>
            <li>
              <p id="amountDue">Amount Due:</p>
              <b>
                &pound;
                {demand
                  ? demand.groundRentToPay?.toLocaleString("en-UK", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : ""}
              </b>
            </li>
            <li>
              <p id="invoiceNumber">Invoice Number:</p>
              <b>{demand ? demand.invoiceNumber : ""}</b>
            </li>
            <li>
              <p id="customerName">Name:</p>
              <b>{demand ? demand.name : ""}</b>
            </li>
            {blockedAccounts.account.isBlocked ? (
              <span style={{ color: "red", fontWeight: "bold" }}>Blocked</span>
            ) : (
              <span>Not Blocked</span>
            )}
          </ul>
        </div>
      </div>
    </GridLayout.Item>
  );

  const closeBatch = async () => {
    // await new DemandService().makeChequePayment(chequePayments);
    const confirmation = confirm(
      `Are you sure you wish to close this batch of £${batchTotal.toLocaleString(
        "en-UK",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}?`
    );
    if (confirmation) {
      if (props.batch.id) {
        const batchID = props.batch.id;
        const closeBatch: ChequeBatch = {
          numberOfCheques: items.length,
          batchTotal: batchTotal,
          closedDate: new Date(),
          batchStatus: BatchStatus.Closed,
          id: batchID,
        };
        await new BatchingService().closeBatch(closeBatch);
        props.changeStep(CARD_BATCH_MANAGEMENT_STEP);
      }
    }
  };

  const disableAddButton = items.length >= 60;

  return (
    <>
      <form onSubmit={handleSubmit(formSubmit)}>
        <div className="u-inline-flex">
          <label htmlFor="batchDate" className="c-label u-font-small">
            ID:&nbsp;
            <span style={{ width: "80px" }}>{props.batch.id}</span>
          </label>
          <label htmlFor="batchDate" className="c-label u-font-small">
            Received Date:&nbsp;
            <input
              type="text"
              name="date"
              id="date"
              ref={register()}
              required
              disabled={true}
              className="c-input u-marg-left-right blank"
              defaultValue={dayjs(displayBatchDate).format("DD-MMM-YY")}
            />
          </label>
          <label htmlFor="batchNumber" className="c-label u-font-small">
            Batch Number:&nbsp;
            <input
              type="text"
              name="batchNumber"
              id="batchNumber"
              ref={register()}
              defaultValue={displayBatchNumber}
              required
              className="c-input blank u-marg-left-right"
              disabled={true}
            />
          </label>
          <label htmlFor="openedBy" className="c-label u-font-small">
            Opened By:&nbsp;
            <input
              type="text"
              name="openedBy"
              id="openedBy"
              autoFocus={true}
              ref={register()}
              defaultValue={displayBatchOpenedBy}
              required
              disabled={true}
              className="c-input u-marg-left-right blank"
            />
          </label>
          <label htmlFor="openedDate" className="c-label u-font-small">
            Opened Date:&nbsp;
            <input
              type="text"
              name="openedDate"
              id="openedDate"
              autoFocus={true}
              ref={register()}
              defaultValue={dayjs(displayBatchOpenDate).format("DD-MMM-YY")}
              required
              disabled={true}
              className="c-input u-marg-left-right blank"
            />
          </label>
        </div>
        <GridLayout columns={2}>
          <GridLayout.Item>
            <div className="l-wrap-box u-width-2 u-marg">
              <div className="c-form +inline +grid">
                <label htmlFor="tenantRef" className="c-label label">
                  Tenant Ref
                  <input
                    type="text"
                    autoFocus={true}
                    className="c-input +text input u-marg-left-right"
                    id="tenantRef"
                    name="tenantRef"
                    required
                    placeholder={"Search..."}
                    ref={(e) => {
                      if (e) {
                        tenantRef.current = e;
                      }
                      register(e);
                    }}
                    onChange={(e) => {
                      updateSearchTerm(e.target.value);
                    }}
                    onBlur={(e) => {
                      checkBlocked(e.target.value);
                    }}
                  />
                </label>
                <h2 className="-heading +h2 u-marg-bottom">{error}</h2>

                <label htmlFor="amount" className="c-label label">
                  Cheque Amount
                  <input
                    id="amount"
                    type="number"
                    step="any"
                    placeholder="0.00"
                    name="amount"
                    className="amount input u-marg-left-right"
                    ref={register({ required: "Cheque amount is required." })}
                  />
                </label>

                <label htmlFor="chequeNumber" className="c-label label">
                  Cheque Number
                  <input
                    id="chequeNumber"
                    type="text"
                    name="chequeNumber"
                    className="c-input input u-marg-left-right"
                    inputMode="numeric"
                    required
                    ref={register()}
                  />
                </label>
              </div>
            </div>
          </GridLayout.Item>
          <GridLayout.Item>
            <div>{showResults ? <Results /> : null}</div>
          </GridLayout.Item>
        </GridLayout>

        <div className="c-field form">
          <div className="c-field-group +inline +equal">
            <div className="c-field">
              <label htmlFor="accountName" className="c-label">
                Account Name<span className="input-required">&#65121;</span>
              </label>
              <input
                id="accountName"
                type="text"
                placeholder="Account Name"
                name="accountName"
                className="c-input input"
                required
                ref={register()}
              />
            </div>
            <div className="c-field">
              <label htmlFor="accountNumber" className="c-label">
                Account Number
              </label>
              <input
                id="accountNumber"
                type="text"
                placeholder="Account Number"
                name="accountNumber"
                className="c-input input"
                minLength={8}
                maxLength={8}
                ref={register()}
              />
            </div>
            <div className="c-field">
              <label htmlFor="sortCode" className="c-label">
                Sort Code
              </label>
              <input
                id="sortCode"
                type="text"
                placeholder="Sort Code"
                name="sortCode"
                className="c-input input"
                minLength={6}
                maxLength={6}
                ref={register()}
              />
            </div>
          </div>
        </div>

        <div className="c-field">
          <label htmlFor="notes" className="c-label">
            Notes:
          </label>
          <textarea
            id="notes"
            name="notes"
            className="input"
            ref={register()}
          />
        </div>
        <AppLayout orientation={AppLayout.Orientation.Horizontal}>
          <AppLayout.Item position={AppLayout.Item.Position.Start}>
            <button
              type="submit"
              className="c-button +1 u-marg-top-bottom"
              disabled={disableAddButton}
            >
              Add
            </button>
          </AppLayout.Item>
          <AppLayout.Item position={AppLayout.Item.Position.End}>
            <label className="u-pull-right u-marg-top-large">
              Total Batch Value:{" "}
              <b>
                &pound;
                {batchTotal.toLocaleString("en-UK", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </b>
            </label>
          </AppLayout.Item>
        </AppLayout>
      </form>
      <BatchTable demands={demand} items={items} removeCheque={removeCheque} />
      <AppLayout orientation={AppLayout.Orientation.Horizontal}>
        <AppLayout.Item position={AppLayout.Item.Position.Start}>
          <button
            type="submit"
            className="c-button u-marg-top-bottom u-fill-pos"
            onClick={addToBatch}
          >
            Save Batch
          </button>
        </AppLayout.Item>
        <AppLayout.Item position={AppLayout.Item.Position.End}>
          <button
            className="c-button u-marg-top u-pull-right"
            onClick={closeBatch}
          >
            Close Batch
          </button>
        </AppLayout.Item>
      </AppLayout>
    </>
  );
};

export default EditBatch;
