import React, {useContext} from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoginContext from '../../contexts/LoginContext';

type AuthenticatedRouteProps = RouteProps & {
  roles?: string[]
}

export default function AuthenticatedRoute({ children, ...rest }: AuthenticatedRouteProps) {
  const loginState = useContext(LoginContext);
  let allowed = loginState.isAuthenticated();

  if (rest.roles) {
    rest.roles.forEach(role => {
      if (!loginState.user?.staffRoles.includes(role)) {
        allowed = false;
      }
    })
  }

  return (
    <Route {...rest}>
      {allowed ? (
        children
      ) : (
        <Redirect to='/admin'  />
      )}
    </Route>
  );
}