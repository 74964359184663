const calculateSurcharge = (amount: number|string, method: string): number => {
    if (!amount || !method || method != "Business Card"){
        return 0;
    }
    
    const numberAmount: number = (typeof amount == "string") ? parseFloat(amount) : amount;

    return numberAmount * 0.025;
}

export {calculateSurcharge};