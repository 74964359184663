import { useState } from "react";
import BlockedAccountService from "../../services/BlockedAccountService";
import IBlockedAccount from "../../../../../shared/entities/IBlockedAccount";

const useBlockedAccounts = () => {
    const blockedAccountsService = new BlockedAccountService();

    const getAccountDetails = async (tenantRef: string) => {
        const demandDetails = await blockedAccountsService.getBlockedAccount(tenantRef);

        if (demandDetails.length){
            setAccount(demandDetails[0]);
            
            return demandDetails[0];
        } else {
            return account;
        }
    };

    const getAccountsBySearchTerm = async (searchTerm: string) => {
        const demandDetails = await blockedAccountsService.getBlockedAccounts(searchTerm);

        return demandDetails;
    };

    const blockAccount = async (account: IBlockedAccount) => {
        return blockedAccountsService.blockAccount(account);
    };

    const unBlockAccount = async (account: IBlockedAccount) => {
        return blockedAccountsService.unBlockAccount(account);
    };

    const initialAccount: IBlockedAccount = {
        tenantRef: "",
        isBlocked: false
    };

    const[account, setAccount] = useState(initialAccount);

    return {account, getAccountDetails, getAccountsBySearchTerm, blockAccount, unBlockAccount, clear: () => {setAccount(initialAccount)}}
};

export default useBlockedAccounts;