import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import DemandService from "../services/DemandService";
import Demand from "../../../../shared/entities/Demand";
import Payment, { PaymentStatus } from "../../../../shared/entities/Payment";
import IStepProps from "../../core/components/wizard/IStepProps";
import { useAsyncAction } from "../../core/hooks/useAsyncAction";

const PaymentStep = (props: any & { demand: Demand; moto?: boolean }) => {
  const [payment, setPayment] = useState<boolean | Payment>(false);

  useEffect(() => {
    new DemandService()
      .startPayment(
        props.demand,
        props.submittedData.source,
        props.submittedData.email,
        props.submittedData.electronicDemands,
        props.submittedData.phoneNumber,
        props.submittedData.paymentMethod,
        props.amountToPay
      )
      .then((payment) => {
        setPayment(payment);
      });
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const makeMotoPayment = async (stripe, cardElement): Promise<void> => {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      throw new Error(error.message);
    } else {
      if (paymentMethod) {
        const updatedPayment = await new DemandService().confirmPayment(
          payment as Payment,
          paymentMethod.id
        );

        if (updatedPayment.status !== PaymentStatus.Success) {
          throw new Error(updatedPayment.paymentError);
        }
      } else {
        throw new Error("Sorry the payment was not successful");
      }
    }
  };

  const makeNormalPayment = async (stripe, cardElement): Promise<void> => {
    let intent = "";
    if ((payment as Payment).paymentIntentSecret) {
      intent = "" + (payment as Payment).paymentIntentSecret;
    }

    const result = await stripe.confirmCardPayment(intent, {
      payment_method: {
        card: cardElement,
        billing_details: {
          address: {
            line1: props.demand.propertyAddress,
          },
          email: props.demand.email,
        },
      },
    });

    let errored = false;
    let paymentIntent;

    if (result.error) {
      if (!result.error.payment_intent) {
        errored = true;
      } else {
        if (result.error.payment_intent.status != "succeeded") {
          errored = true;
        } else {
          paymentIntent = result.error.payment_intent;
        }
      }
    } else {
      paymentIntent = result?.paymentIntent;
    }

    if (errored) {
      throw new Error(result.error.message);
    } else {
      // The payment has been processed!
      if (paymentIntent.status === "succeeded") {
        (payment as Payment).status = PaymentStatus.Success;

        await new DemandService().updatePayment(payment as Payment);
      } else {
        throw new Error("The payment was not successful");
      }
    }
  };

  const payNowAction = useAsyncAction();

  const onPaymentSubmitted = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    payNowAction.trigger(async () => {
      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        let intent = "";
        if ((payment as Payment).paymentIntent) {
          intent = "" + (payment as Payment).paymentIntent;
        }

        try {
          let success = false;
          if (props.moto) {
            await makeMotoPayment(stripe, cardElement);
          } else {
            await makeNormalPayment(stripe, cardElement);
          }

          props.changeStep && props.changeStep("PaymentCompleteStep");
          props.setPaymentId && props.setPaymentId((payment as Payment).id);
        } catch (e) {
          // Show error to your customer (e.g., insufficient funds)
          (payment as Payment).status = PaymentStatus.Failed;
          (payment as Payment).paymentError = e.message;

          await new DemandService().updatePayment(payment as Payment);

          alert(e.message);
        }
      }
    });
  };

  return (
    <form onSubmit={onPaymentSubmitted}>
      <div className="l-wrap u-width-4">
        <div className="cc-module u-marg-bottom">
          <div className="cc-module__header">
            <h3 className="o-flex__item u-white">Payment</h3>
          </div>
          <div className="cc-module__body">
            <p className="u-marg-bottom">
              Card details are processed using our secure payment provider,
              Stripe Payments.
            </p>
            {payment && (
              <>
                <label className="c-label">Card details</label>
                <CardElement options={cardElementOptions} />
              </>
            )}
            {!payment && <p>Loading payment details...</p>}
          </div>
        </div>
        <button
          className="c-button +1 +large"
          disabled={payNowAction.state.triggered}
        >
          Pay Now
        </button>
      </div>
    </form>
  );
};

export default PaymentStep;
