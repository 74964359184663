import React, { useEffect, useState } from "react";
import { Button, GridLayout } from "morse-css";
import useDebounce from "../../receipts/hooks/useDebounce";
import useDemand from "../../payment/hooks/useDemand";
import IBlockedAccount from "../../../../shared/entities/IBlockedAccount";
import useBlockedAccounts from "./hooks/useBlockedAccounts";
import { Link } from "react-router-dom";

const SearchBlockedAccounts = (props: any) => {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const [accounts, setAccounts] = useState<IBlockedAccount[]>();
  const { getAccountsBySearchTerm } = useBlockedAccounts();
  const [showError, setShowError] = useState<boolean>(false);
  const [loadingFlag, setLoadingFlag] = useState<boolean>(false);

  let loadMessage = "Loading...";
  let searchMessage = "Search results for '" + searchTerm + "'";

  const loadResults = async () => {
    try {
      setShowError(false);
      setLoadingFlag(true);
      await setAccounts(await getAccountsBySearchTerm(searchTerm));
    } catch (e) {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (searchTerm.length) {
      loadResults();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      setLoadingFlag(false);
    }
    searchTerm.length === 0 && setLoadingFlag(false);
    if (accounts && accounts.length === 0 && searchTerm.length > 0) {
      setLoadingFlag(false);
    }
  }, [accounts, searchTerm]);

  const updateSearchTerm = useDebounce((value: string) => {
    if (value.length > 0) {
      setSearchTerm(value);
    } else {
      setAccounts([]);
      setSearchTerm("");
    }
  }, 500);

  return (
    <div>
      <h2 className="c-heading +h2 u-marg-bottom">Blocked Accounts</h2>
      <div className={""}>
        <h3 className="c-heading +h3 u-marg-bottom">Search by Tenant Ref</h3>
        <GridLayout columns={2}>
          <GridLayout.Item>
            <input
              type="text"
              autoFocus
              className="c-input +text +adaptive +auto-fill"
              placeholder={"Search..."}
              onChange={(e) => {
                updateSearchTerm(e.target.value);
                props.hideUpdateMessage();
              }}
            />
          </GridLayout.Item>
          <GridLayout.Item>
            {props.showUpdateSuccess && (
              <div
                className="c-alert u-fill-pos u-bold u-justify-content-center"
                style={{ width: "250px", display: "inline-block" }}
              >
                Account Updated
              </div>
            )}
          </GridLayout.Item>
        </GridLayout>
        <h2 className="c-heading +h2 u-marg-top">
          {searchTerm?.length > 0 ? searchMessage : ""}
        </h2>
      </div>

      <div>
        {accounts && accounts.length > 0 ? (
          <table className="u-marg-top u-marg-bottom c-table +lined">
            <thead>
              <tr>
                <th>Tenant Ref</th>
                <th>Invoice Number</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Property Address</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account: IBlockedAccount) => {
                return account.demand ? (
                  <tr key={account.id}>
                    <td>{account.tenantRef}</td>
                    <td>{account.demand?.invoiceNumber}</td>
                    <td>{account.demand?.name}</td>
                    <td>{account.demand?.email}</td>
                    <td>{account.demand?.phoneNumber}</td>
                    <td>{account.demand?.propertyAddress}</td>
                    {/* allowOverlappingEvents on morse Button is stopping the button from throwing an update state on unmounted component warning - See SearchPayments.tsx */}
                    <td>
                      <Button
                        color={Button.Color.Primary}
                        allowOverlappingEvents={true}
                        onClick={() => {
                          props.onRefSelected(searchTerm, account);
                        }}
                      >
                        {account.isBlocked
                          ? "Unblock Payment"
                          : "Block Payment"}
                      </Button>
                    </td>
                  </tr>
                ) : (
                  <tr key={account.id}>
                    <td>{account.tenantRef}</td>
                    <td colSpan={5}>No demand found</td>
                    {/* allowOverlappingEvents on morse Button is stopping the button from throwing an update state on unmounted component warning - See SearchPayments.tsx */}
                    <td>
                      <Button
                        color={Button.Color.Primary}
                        allowOverlappingEvents={true}
                        onClick={() => {
                          props.onRefSelected(searchTerm, account);
                        }}
                      >
                        {account.isBlocked
                          ? "Unblock Payment"
                          : "Block Payment Anyway"}
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : searchTerm && !loadingFlag ? (
          <p>No results found</p>
        ) : (
          <p></p>
        )}
        {showError && (
          <div>
            <div
              className="c-alert u-fill-neg u-bold u-justify-content-center"
              style={{ width: "250px" }}
            >
              Server Error
            </div>
            <p>Try again in a few seconds or contact support</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBlockedAccounts;
