import {Column, Entity, PrimaryColumn, PrimaryGeneratedColumn} from "typeorm";

@Entity()
export default class Client {
    @PrimaryGeneratedColumn()
    id?: number;

    @Column({default: ""})
    public name: string = '';    

    @Column("text", {nullable: true})
    logo: string = "";

    @Column({default: ""})
    brandingColor: string = "";

    @Column({default: ""})
    websiteURL: string = "";

    @Column({default: ""})
    stripePublicKey: string = "";

    @Column({default: ""})
    stripePrivateKey: string = "";

    @Column({default: ""})
    customerServiceEmail: string = "";

    @Column({default: ""})
    blockedAlertEmail: string = "";

    @Column({default: ""})
    fromEmailAddress: string = "";

    @Column("text", {nullable: true})
    edemandEmailBody: string = "";

    @Column({default: ""})
    siteName: string = "";

    @Column({default: ""})
    paymentReportSplittingPrefix: string = "";

    @Column({default: ""})
    logoName: string = "";

    @Column({default: ""})
    clientAddress: string = "";

    @Column({type: "boolean", default: false})
    public disabled: boolean = false;

    @Column({type: "boolean", default: false})
    public enableEdemands: boolean = false;

    @Column({type: "boolean", default: false})
    public enableSurcharges: boolean = false;

    @Column({type: "boolean", default: false})
    public enableMoto: boolean = false;

    @Column({type: "boolean", default: false})
    public enableRent: boolean = false;

    @Column({type: "boolean", default: false})
    public enableResend: boolean = false;

    @Column({type: "boolean", default: false})
    public enableCheques: boolean = false;

    @Column({default: ""})
    paidTo: string = "";

    @Column({default: "Tenant Reference"})
    tenantRefTerm: string = "Tenant Reference";

    @Column({default: "G230BAL5"})
    tenantRefExample: string = "G230BAL5";

    @Column({default: "", length: 800})
    tenantRefDescription: string = "";

    @Column({type: "boolean", default: false})
    public paymentsDisabled: boolean = false;

    @Column({default: ""})
    paymentsPhoneNumber: string = "";

    @Column("text", {nullable: true})
    faqText: string = "";    
}