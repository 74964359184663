import React, { useState, useContext, useEffect } from "react";
import Demand from "../../../../shared/entities/Demand";
import DemandContext from "../../payment/contexts/DemandContext";
import { Link } from "react-router-dom";
import { Button } from "morse-css/dist/lib/components/Button";
import LoginContext from "../../core/contexts/LoginContext";

type PaymentCompleteProps = {
  moto?: boolean;
  submittedData?: {};
  paymentId?: number;
};

const PaymentCompleteStep = (props: PaymentCompleteProps) => {
  const loginState = useContext(LoginContext);

  useEffect(() => {
    if (loginState.user) {
      if (loginState.user.staffRoles.includes("tricord")) {
        alert("Have you resumed call recording?");
      }
    }
  }, []);

  return (
    <DemandContext.Consumer>
      {(demand) => {
        const realDemand = demand as Demand;
        return (
          <div className="l-wrap u-width-4 s-cms-content">
            <h2>Thank you for choosing to pay your Rent online.</h2>

            {realDemand.electronicDemands && (
              <p>
                Thank you also for signing up to receive your rent demand
                electronically.
              </p>
            )}

            {props.paymentId && (
              <p>
                Your payment reference is <b>{props.paymentId}</b>
              </p>
            )}

            <p>
              A confirmation email will be sent to you shortly - please treat
              this as your receipt.
            </p>

            <p>
              If you have any queries regarding your rent, please send the
              details to the following email address -
              <a
                href={
                  "mailto: test@groundpay.co.uk?subject=Rent Query: Tenant Ref=" +
                  realDemand.tenantRef +
                  ", Invoice Number=" +
                  realDemand.invoiceNumber
                }
              >
                groundrents@groundpay.com
              </a>
            </p>

            {props.moto && (
              <p>
                <a href="/admin/rent">
                  <Button color={Button.Color.Primary}>Add Another</Button>
                </a>
              </p>
            )}
            {!props.moto && (
              <p>
                <input
                  type="button"
                  className="c-button c-button--primary"
                  value="Return Home"
                />
              </p>
            )}
          </div>
        );
      }}
    </DemandContext.Consumer>
  );
};

export default PaymentCompleteStep;
