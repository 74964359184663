import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import Step from "../../src/core/components/wizard/Step";
import PayeeContactDetailsStep from "./payment-wizard/PayeeContactDetailsStep";
import PaymentStep from "./payment-wizard/PaymentStep";
import Wizard from "../core/components/wizard/Wizard";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCompleteStep from "./payment-wizard/PaymentCompleteStep";
import RentDemand from "../../../shared/entities/Demand";
import RentDemandContext from "./contexts/RentDemandContext";
import ClientContext from "../core/contexts/ClientContext";
import Client from "../../../shared/entities/Client";
import { GridLayout, Button, Border } from "morse-css";
import BlockedAccountService from "../admin/services/BlockedAccountService";

type PayRentProps = {
  moto?: boolean;
};

const PayRent = (props: PayRentProps) => {
  const { register, handleSubmit, watch, errors } = useForm<RentDemand>();
  const [formState, setFormState] = useState<RentDemand>();
  const [paymentId, setPaymentId] = useState<number>(0);

  const onSubmit = async (data: any) => {
    // Look up blocking status.
    const blockedAccountsService = new BlockedAccountService();
    try {
      const blockedDetails = await blockedAccountsService.getBlockedAccount(
        data["tenantRef"]
      );
      if (blockedDetails[0] && blockedDetails[0].isBlocked) {
        data["isBlocked"] = true;
        blockedAccountsService.sendNotification(
          data["tenantRef"],
          "Rent Payment"
        );
      }
    } catch (e) {}

    setFormState(data);
  };

  const pushDemandState = (data: any) => {
    setFormState({ ...formState, ...data });
  };

  const client: Client = useContext(ClientContext) as Client;

  const stripePromise = loadStripe(client.stripePublicKey);

  return (
    <div>
      {formState?.tenantRef == undefined ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="o-layout">
            {props.moto && (
              <div className="c-alert u-marg-bottom-large">
                <p className="u-flex-grow-1">
                  <b>Do you require a receipt?</b>
                  <br />
                  If Yes, do you have an email address?
                  <br />
                  If Yes, continue
                  <br />
                  <i>
                    <b>If No, please post your demand into us with an SAE</b>
                  </i>
                </p>
              </div>
            )}

            <GridLayout columns={1} className="+cols-2@m">
              <GridLayout.Item>
                <div className="l-wrap u-width-2">
                  <div className="u-pad u-pad--heavy@m">
                    <div className="c-input-group">
                      <input
                        id="tenantRef"
                        className="c-input +text +no-border +fill"
                        autoFocus
                        type="text"
                        name="tenantRef"
                        ref={register}
                        placeholder="Tenant Reference (e.g G230BAL5)"
                        maxLength={15}
                      />

                      <span
                        id="TenantRefValidation"
                        className="u-micro u-red"
                      ></span>

                      <input
                        id="invoiceNum"
                        type="text"
                        name="invoiceNumber"
                        ref={register}
                        className="c-input +text +no-border +fill"
                        placeholder="Invoice Number"
                        maxLength={15}
                      />

                      <input
                        id="propertyAddress"
                        type="text"
                        name="propertyAddress"
                        ref={register}
                        className="c-input +text +no-border +fill"
                        placeholder="First Line of Property Address"
                        maxLength={255}
                      />

                      {props.moto && (
                        <select
                          id="source"
                          name="source"
                          ref={register}
                          required
                          className="c-input +select +no-border +fill"
                        >
                          <option value="">Source</option>
                          <option>Telephone</option>
                          <option>Walk in</option>
                        </select>
                      )}

                      <span
                        id="InvoiceNumberValidation"
                        className="u-micro u-red"
                      ></span>
                    </div>
                    <p className="u-font-small u-translucent u-marg-top-bottom">
                      Please ensure that your Rent Demand showing the invoice
                      number was issued not more than <b>six months ago</b>.
                    </p>
                    <Button color={Button.Color.Primary}>Continue</Button>
                  </div>
                </div>
              </GridLayout.Item>
              <GridLayout.Item className="cc-info-module">
                <div className="l-wrap u-width-2">
                  <div className="u-font-small u-pad u-pad--heavy@m">
                    <p className="u-marg-bottom">
                      Please note that your Tenant Reference is made up from:
                    </p>
                    <ul className="u-list-dash">
                      <li>An initial letter (G, H or Y)</li>
                      <li>
                        Three numbers (usually the number of your house preceded
                        by zeros if required)
                      </li>
                      <li>
                        Three letters (usually the first three letters of your
                        road, or street)
                      </li>
                      <li>
                        And a final check character - this may be a letter or a
                        number
                      </li>
                    </ul>
                  </div>
                </div>
              </GridLayout.Item>
            </GridLayout>
          </div>
        </form>
      ) : (
        <div>
          <RentDemandContext.Provider value={formState as RentDemand}>
            <Elements stripe={stripePromise}>
              <Wizard defaultStep={"PayeeContactDetailsStep"}>
                <Step name={"PayeeContactDetailsStep"}>
                  <PayeeContactDetailsStep
                    submittedData={formState}
                    submitData={pushDemandState}
                  />
                </Step>
                <Step name={"ContinueToPaymentStep"}>
                  <PaymentStep
                    submittedData={formState}
                    demand={formState as RentDemand}
                    setPaymentId={setPaymentId}
                  />
                </Step>
                <Step name="PaymentCompleteStep">
                  <PaymentCompleteStep
                    submittedData={formState}
                    moto={props.moto}
                    paymentId={paymentId}
                  />
                </Step>
              </Wizard>
            </Elements>
          </RentDemandContext.Provider>
        </div>
      )}
    </div>
  );
};

export default PayRent;
