import ReceiptService from "../services/ReceiptService";

const usePaymentUpdate = () => {
    const paymentService = new ReceiptService();

    const updatePaymentDetails = async (id: number, email: string) => {
        const paymentDetails = await paymentService.updatePaymentEmail(id, email);

        return paymentDetails;
    };

    return {updatePaymentDetails}
};

export default usePaymentUpdate;