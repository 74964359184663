import RentDemand from '../../../../shared/entities/RentDemand';
import Payment, { Source } from '../../../../shared/entities/Payment';
import WebsiteConfiguration from '../../../configurations/WebsiteConfiguration';

class RentService {

    async startPayment(demand: RentDemand): Promise<Payment> {
        return await WebsiteConfiguration.current.apiTransport.post<any>(
            "rent-payments", demand)
    }

    async updatePayment(payment: Payment): Promise<Payment> {
        return await WebsiteConfiguration.current.apiTransport.put<any>("payments/" + payment.id, payment)
    }
}

export default RentService;