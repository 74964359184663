import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {AppLayout} from 'morse-css/dist/lib/layout/AppLayout';
import {WrapLayout} from 'morse-css/dist/lib/layout/WrapLayout';

const StandardLayout = (props: any) => {

    return (        
        <AppLayout orientation={AppLayout.Orientation.Vertical}>
            <AppLayout.Item position={AppLayout.Item.Position.Start} className="u-border-bottom u-pad"><Header /></AppLayout.Item>            
            <AppLayout.Item position={AppLayout.Item.Position.Middle}>
                <WrapLayout className="u-width-6">{props.children}</WrapLayout>
            </AppLayout.Item>
            <AppLayout.Item position={AppLayout.Item.Position.End} className="u-border-top u-pad"><Footer /></AppLayout.Item>        
        </AppLayout>        
    )
};

export default StandardLayout;