import React, { useCallback, useState } from "react";
import SearchBlockedAccounts from "./SearchBlockedAccounts";
import UpdateBlockedAccount from "./UpdateBlockedAccount";
import IBlockedAccount from "../../../../shared/entities/IBlockedAccount";

const BlockedAccounts = () => {
  const [account, setAccount] = useState<IBlockedAccount>();
  const [searchTerm, setSearchTerm] = useState("");
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);

  const onRefSelected = useCallback(
    (searchTermData: string, account?: IBlockedAccount) => {
      setSearchTerm(searchTermData);
      if (account) {
        setAccount(account);
      } else {
        setAccount({ tenantRef: searchTermData, isBlocked: false });
      }
    },
    []
  );

  const switchToSearchView = () => {
    setAccount(undefined);
  };

  const confirmUpdate = () => {
    setShowUpdateSuccess(true);
    setAccount(undefined);
    setSearchTerm("");
  };

  const hideUpdateMessage = () => {
    setShowUpdateSuccess(false);
  };

  return (
    <div>
      {!account ? (
        <SearchBlockedAccounts
          onRefSelected={onRefSelected}
          searchTerm={searchTerm}
          showUpdateSuccess={showUpdateSuccess}
          hideUpdateMessage={hideUpdateMessage}
        />
      ) : (
        <UpdateBlockedAccount
          account={account}
          searchBlockedAccounts={switchToSearchView}
          confirmUpdate={confirmUpdate}
        />
      )}
    </div>
  );
};

export default BlockedAccounts;
