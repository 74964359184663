import React, {useCallback, useState} from 'react';
import SearchDemands from "./SearchDemands";
import UpdateDemandInfo from "./UpdateDemandInfo";
import {IDemand} from "../../../shared/entities/IDemand";

const Demands = () => {

    const [demand, setDemand] = useState<IDemand>();
    const [searchTerm, setSearchTerm] = useState('');
    const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);

    const demandSelected = useCallback((demandData: IDemand, searchTermData: string) => {
        setSearchTerm(searchTermData);
        setDemand(demandData);
    }, []);

    const switchToSearchView = () => {
        setDemand(undefined);
    };

    const confirmUpdate = () =>{
      setShowUpdateSuccess(true);
    };

    const hideUpdateMessage = () =>{
        setShowUpdateSuccess(false);
    };

    return(
        <div>
            {!demand ?
                <SearchDemands
                    onDemandSelected = {demandSelected}
                    searchTerm = {searchTerm}
                    showUpdateSuccess = {showUpdateSuccess}
                    hideUpdateMessage = {hideUpdateMessage}
                />
                :
                <UpdateDemandInfo
                    demand = {demand}
                    searchDemands={switchToSearchView}
                    confirmUpdate = {confirmUpdate}
                />
            }
        </div>
    )
};

export default Demands;