import EDemandService from "../services/EDemandService";
import {useEffect, useRef, useState} from "react";
import { IElectronicDemand } from "../../core/components/edemands/IElectronicDemand";

export enum SendingStages {
    Sending,
    NotSending,
    FinishedSending
}

const useEDemand = () => {

    const eDemandService = new EDemandService();
    const [progressTotal, setProgressTotal] = useState<number>(0);
    const [currentState, setCurrentState] = useState<SendingStages>(SendingStages.NotSending);    
    const [stats, setStats] = useState<IElectronicDemand>({
        unqueued: 0,
        unsent: 0,
        totalHeldEDemands: 0,
        totalDemands: 0,
        totalGroundRentToPay: 0,
        totalPaid: 0,
        totalGroundRentPaid: 0,
        totaleDemandsSent: 0,
        totaleDemandsAsPreference: 0,
        totalBlocked: 0
    });

    useEffect(() => {
        populateEDemandStats();
    }, []);

    const populateEDemandStats = async () => {
        const stats = await eDemandService.getEDemandStats();
        setStats(stats)
        return stats;
    };

    const populateQueuedEDemands = async () => {
        let stats = await eDemandService.populateQueuedEDemands();
        setProgressTotal(stats.unsent);
        setStats(stats);
        return stats;
    };

    useEffect(() => {
        if (currentState === SendingStages.Sending){
            if (stats?.unsent) {
                send();
            } else {
                setCurrentState(SendingStages.FinishedSending)
            }
        } else {
            setCurrentState(SendingStages.NotSending)
        }        
    }, [currentState, stats?.unsent]);

    const send = async () => {
        const stats = await eDemandService.sendBatchedEDemands();        
        setStats(stats);      
    };

    const sendEDemands = async () => {
        setCurrentState(SendingStages.Sending);
    };

    const stopSendingEDemands = () => {
        setCurrentState(SendingStages.NotSending);
    };

    return {stats, populateQueuedEDemands, currentState, sendEDemands, progressTotal, stopSendingEDemands, populateEDemandStats};
};

export default useEDemand;