import React, {useState} from 'react';
import logo from "../core/images/groundpay-grey.png";

const ErrorHandler = (props: any) => {

    const [statusCode] = useState(props.statusCode);
    const [message] = useState(props.message);

    return (
        <div className="u-flex u-align-items-center u-justify-content-center u-marg-top-bottom-large">
            <div className="u-pad u-shadow-medium +quiet u-width-2 ">
                <div className="u-flex u-align-items-center u-justify-content-center">
                    <img src={logo} alt="" className="c-image"/>
                </div>

                <div className="u-pad-left-right u-justify-content-center">
                    {statusCode && <h2 className="c-heading +h1 u-marg-bottom u-flex u-align-items-center u-justify-content-center">Error: {statusCode}</h2>}
                    {message && <h2 className="c-heading +h2 u-marg-bottom u-flex u-align-items-center u-justify-content-center">{message}</h2>}
                    <h2 className="c-heading +h3 u-marg-bottom">We're sorry, we could not find the information you're looking for.</h2>
                    <p className="u-marg-top-bottom">This could be because:</p>
                    <ul className="u-bullet-list u-pad-left-right">
                        <li>Our server has stopped responding.</li>                        
                        <li>The URL has been entered incorrectly.</li>
                        <li>Your account may not have the correct permissions.</li>
                    </ul>
                </div>
                <div className="u-flex u-align-items-center u-justify-content-center u-marg-top-bottom-large">
                    <button className="c-button +1 +long" onClick={() => {window.history.back()}}>Back</button>
                </div>
            </div>
        </div>
    )
};

export default ErrorHandler;