import React, { useState, useContext, useEffect } from "react";
import Demand from "../../../../shared/entities/Demand";
import DemandContext from "../contexts/DemandContext";
import { Link } from "react-router-dom";
import LoginContext from "../../core/contexts/LoginContext";
import ClientContext from "../../core/contexts/ClientContext";

type PaymentCompleteProps = {
  moto?: boolean;
  submittedData?: { [index: string]: any };
  paymentId?: number;
};

const PaymentCompleteStep = (props: PaymentCompleteProps) => {
  const loginState = useContext(LoginContext);
  const client = useContext(ClientContext);

  useEffect(() => {
    if (loginState.user) {
      if (loginState.user.staffRoles.includes("tricord")) {
        alert("Have you resumed call recording?");
      }
    }
  }, []);

  return (
    <DemandContext.Consumer>
      {(demand) => {
        const realDemand = demand as Demand;
        return (
          <div className="l-wrap u-width-4 s-cms-content">
            <h2>Thank you for choosing to pay your Ground Rent online.</h2>

            {props.submittedData?.electronicDemands && (
              <p>
                Thank you also for signing up to receive your rent demand
                electronically.
              </p>
            )}

            {props.submittedData?.email.trim() != "" && (
              <p>
                A confirmation email will be sent to you shortly - please treat
                this as your receipt.
              </p>
            )}

            {props.paymentId && (
              <p>
                Your payment reference is <b>{props.paymentId}</b>
              </p>
            )}

            <p>
              If you have any queries regarding your ground rent, please send
              the details to the following email address -
              <a
                href={
                  "mailto: " +
                  client?.customerServiceEmail +
                  "?subject=Rent Query: Tenant Ref=" +
                  realDemand.tenantRef +
                  ", Invoice Number=" +
                  realDemand.invoiceNumber
                }
              >
                {client?.customerServiceEmail}
              </a>
            </p>

            {props.moto && (
              <p>
                <a href="/admin/moto">
                  <button className="c-button c-button--primary">
                    Add Another
                  </button>
                </a>
              </p>
            )}
            {!props.moto && (
              <p>
                <Link to="/">
                  <button className="c-button c-button--primary">
                    Return Home
                  </button>
                </Link>
              </p>
            )}
          </div>
        );
      }}
    </DemandContext.Consumer>
  );
};

export default PaymentCompleteStep;
