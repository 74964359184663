import {Border, GridLayout} from "morse-css";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Payment from "../../../shared/entities/Payment";
import ChequeBatch, {BatchStatus} from "../../../shared/entities/ChequeBatch";
import BatchingService from "./services/BatchingService";
import {EDIT_BATCH_STEP} from "./ChequeBatchManagement";

const NewBatch = (props: any) => {

    const {register, handleSubmit} = useForm<Payment>();

    const startBatch = async (data) => {
        const insertBatch: ChequeBatch = {
            batchTotal: 0,
            numberOfCheques: 0,
            openedDate: new Date(),
            batchDate: data.date,
            batchNumber: "",
            username: "",
            batchStatus: BatchStatus.Started,

        }

        let batch = await new BatchingService().createNewBatch(insertBatch);
        props.changeBatch({batch, payments: []});
        props.changeStep(EDIT_BATCH_STEP);
    }

   return <>
       <form onSubmit={handleSubmit(startBatch)}>
           <div className="+inline +grid">
               <h1>Start a new cheque batch</h1>
               <label htmlFor="batchDate" className="c-label">Received Date:
                   <input type="date" name="date" id="date" autoFocus={true} ref={register()} required
                          className="c-input u-marg-left-right"/></label>
                <p>Enter the date the cheques were originally received to the office.</p>
               <button type="submit" className="c-button +1 u-marg-top-bottom">Start Batch</button>
           </div>
       </form>
    </>;

};

export default NewBatch;