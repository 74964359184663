import BlockedAccount from "../../../../shared/entities/BlockedAccounts";
import IBlockedAccount from "../../../../shared/entities/IBlockedAccount";
import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";

class BlockedAccountService {
  async checkPayments(): Promise<number> {
    return await WebsiteConfiguration.current.apiTransport.get<any>(
      "payments/check",
      {}
    );
  }

  async sendNotification(
    tenantRef: string,
    feature: string,
  ): Promise<BlockedAccount[]> {
    return await WebsiteConfiguration.current.apiTransport.post<any>(
      "blocked/notifications/" + tenantRef + "/" + feature, {}      
    );
  }

  async getBlockedAccount(
    searchTerm: string,
  ): Promise<BlockedAccount[]> {
    return await WebsiteConfiguration.current.apiTransport.get<any>(
      "blocked/tenantRef",{searchTerm},      
    );
  }

  async getBlockedAccounts(searchTerm: string): Promise<BlockedAccount[]> {
    return await WebsiteConfiguration.current.apiTransport.get<any>("blocked", {
      searchTerm,
    });
  }

  // While this is a 'post', in the back end it will update a blocked account
  // record if it already exists
  async blockAccount(blockedAccount: IBlockedAccount): Promise<BlockedAccount> {      
    return await WebsiteConfiguration.current.apiTransport.post<any>(
      "blocked",
      {
        tenantRef: blockedAccount.tenantRef
      }
    );
  }

  // While this is a 'delete' in the back end it just marks it not blocked 
  async unBlockAccount(blockedAccount: IBlockedAccount): Promise<BlockedAccount> {      
    return await WebsiteConfiguration.current.apiTransport.delete<any>(
      "blocked/" + blockedAccount.tenantRef,
      {}
    );
  }
}

export default BlockedAccountService;
