import React, {useState} from "react";
import {IPayment} from "../core/components/receipts/IPayment";
import usePaymentUpdate from "./hooks/usePaymentUpdate";
import { GridLayout, Border, Button } from 'morse-css';

interface IResendReceipts {
    payment: IPayment,
    searchPayments: {}
}

const ResendReceipts = (props:IResendReceipts) => {

    const [paymentRecord] = useState<IPayment>(props.payment);
    const [emailAddress, setEmailAddress] = useState(props.payment.email);
    const {updatePaymentDetails} = usePaymentUpdate();
    const [resendDisabled, setResendDisabled] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const updateEmailAddress = (value: string) => {
        setEmailAddress(value);
    };
    const buttonPush = async () => {
        setResendDisabled(true);
        paymentRecord.email = emailAddress;
        try{
            await updatePaymentDetails(paymentRecord.id, emailAddress);
            setShowSuccess(true);
            setShowError(false);
        } catch (e) {
            setResendDisabled(false);
            setShowError(true);
        }
    };

    return (
        <div>
            <h2 className="c-heading +h2 u-marg-bottom">Resend Receipts</h2>
            <GridLayout columns={1} className="+cols-2@m">
                <GridLayout.Item>
                    <div>
                        <h3 className="c-heading +h3 u-marg-bottom">Update email address if required</h3>
                        <div>
                            {!showSuccess ? <input type="text" className="c-input +text +adaptive" style={{width: "250px"}} value={emailAddress}
                                   onChange={e => {
                                        updateEmailAddress(e.target.value);
                                   }
                            }/> :
                            <div className="c-alert u-fill-pos u-bold u-justify-content-center" style={{width: "250px", display: "inline-block"}}>
                                Email Sent
                            </div>}
                            <button className="c-button +1" onClick={buttonPush} style={{float: "right"}} disabled={resendDisabled}>Resend Receipt</button>
                            {showError && (<div><div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px"}}>
                                Failed to Send Email
                            </div><p>Please check the email address is correct and try again in a few seconds</p></div>)}

                        </div>
                        <div className="u-marg-top-large">
                            <Button onClick={props.searchPayments}>Return to Search</Button>
                        </div>
                    </div>
                </GridLayout.Item>
                <GridLayout.Item className="cc-info-module">
                    <div className="l-wrap u-width-2">
                        <div className="u-font-small u-pad u-pad--heavy@m">
                            <h2 className="c-heading +h3">Payment Details:</h2>
                            <ul className="u-list-dash">
                                <li><h2 className="c-heading +h4">Tenant Ref:</h2> {paymentRecord.tenantRef}</li>
                                <li><h2 className="c-heading +h4">Invoice Number:</h2> {paymentRecord.invoiceNumber}</li>
                                <li><h2 className="c-heading +h4">Property Address:</h2> {paymentRecord.propertyAddress}</li>
                                <li><h2 className="c-heading +h4">Type of Payment:</h2> {paymentRecord.type}</li>
                            </ul>
                        </div>
                    </div>
                </GridLayout.Item>
            </GridLayout>
        </div>
    )
};

export default ResendReceipts;