import React, { useEffect, useState } from "react";
import { Border, GridLayout, Button } from "morse-css";
import { useForm } from "react-hook-form";
import Demand from "../../../shared/entities/Demand";
import DemandService from "../payment/services/DemandService";
import BlockedAccountService from "../admin/services/BlockedAccountService";
import IBlockedAccount from "../../../shared/entities/IBlockedAccount";

const UpdateDemandInfo = (props: any) => {
  const [demand] = useState<Demand>(props.demand);
  const [status, setStatus] = useState<IBlockedAccount>();
  const { register, handleSubmit } = useForm<Demand>();
  const [showError, setShowError] = useState<boolean>(false);

  const onSubmit = async (data) => {
    const confirmation = confirm(
      "Are you sure you want to update this demand?"
    );
    if (confirmation) {
      props.demand.email = data.email;
      props.demand.name = data.name;
      props.demand.phoneNumber = data.phoneNumber;
      props.demand.electronicDemands = data.electronicDemands;

      new DemandService()
        .updateDemand(props.demand)
        .then(() => {
          setShowError(false);
          props.searchDemands();
          props.confirmUpdate();
        })
        .catch(() => {
          setShowError(true);
        });
    }
  };

  useEffect(() => {
    const service = new BlockedAccountService();
    service
      .getBlockedAccount(demand.tenantRef)
      .then((accounts) => {
        if (accounts.length > 0) {
          setStatus(accounts[0]);
        }
      })
      .catch(() => {
        setStatus({ tenantRef: demand.tenantRef, isBlocked: false });
      });
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="c-heading +h2 u-marg-bottom">Update Demand Details</h2>
        <GridLayout columns={2}>
          <GridLayout.Item>
            <div>
              <GridLayout columns={2}>
                <GridLayout.Item>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Name:</label>
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Email:</label>
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Phone Number:</label>
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <label className="c-label">Signup to EDemands:</label>
                  </div>
                </GridLayout.Item>
                <GridLayout.Item>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="c-input +text +adaptive"
                      style={{ width: "250px" }}
                      defaultValue={props.demand.name}
                      ref={register()}
                    />
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="c-input +text +adaptive"
                      style={{ width: "250px" }}
                      defaultValue={props.demand.email}
                      ref={register()}
                    />
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <input
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="c-input +text +adaptive"
                      style={{ width: "250px" }}
                      defaultValue={props.demand.phoneNumber}
                      ref={register()}
                    />
                  </div>
                  <div className="u-marg-top" style={{ height: "32px" }}>
                    <input
                      type="checkbox"
                      id="electronicDemands"
                      name="electronicDemands"
                      className="c-input"
                      defaultChecked={props.demand.electronicDemands}
                      ref={register()}
                    />
                  </div>
                </GridLayout.Item>
              </GridLayout>
              <GridLayout columns={3} className="u-marg-top-large">
                <GridLayout.Item>
                  <div>
                    <Button onClick={props.searchDemands}>
                      Return to Search
                    </Button>
                  </div>
                </GridLayout.Item>
                <GridLayout.Item>
                  <div>
                    <button type="submit" className="c-button +1">
                      Update Record
                    </button>
                  </div>
                </GridLayout.Item>
                <GridLayout.Item>
                  <div>
                    {showError && (
                      <div
                        className="c-alert u-fill-neg u-bold u-justify-content-center"
                        style={{ width: "250px" }}
                      >
                        Server could not be updated. Please try again in a few
                        mins
                      </div>
                    )}
                  </div>
                </GridLayout.Item>
              </GridLayout>
            </div>
          </GridLayout.Item>
          <GridLayout.Item border={Border.Left}>
            <div className="l-wrap-box u-width-2">
              <div className="u-font-small u-pad u-pad--heavy@m">
                <h2 className="c-heading +h3">Demand Details:</h2>
                <ul className="u-list-dash">
                  <li>
                    <h2 className="c-heading +h4">Property Address:</h2>{" "}
                    {demand.propertyAddress}
                  </li>
                  <li>
                    <h2 className="c-heading +h4">Tenant Ref:</h2>{" "}
                    {demand.tenantRef}
                  </li>
                  <li>
                    <h2 className="c-heading +h4">Invoice Number:</h2>{" "}
                    {demand.invoiceNumber}
                  </li>
                  <li>
                    <h2 className="c-heading +h4">Current Balance:</h2> &pound;
                    {demand.groundRentToPay?.toFixed?.(2)}
                  </li>
                  <li>
                    <h2 className="c-heading +h4">Blocked Status:</h2>{" "}
                    {status &&
                      (status.isBlocked ? (
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          Blocked
                        </span>
                      ) : (
                        <span>Not Blocked</span>
                      ))}
                  </li>
                </ul>
              </div>
            </div>
          </GridLayout.Item>
        </GridLayout>
      </form>
    </div>
  );
};

export default UpdateDemandInfo;
