import React from "react";
import logo from "../core/images/groundpay-grey.png";

const ClientLoading = () => {

    return (

        <div className="u-flex u-align-items-center u-justify-content-center u-marg-top-bottom-large">
            <div className="u-pad u-shadow-medium  u-width-2 ">
                <div className="u-flex u-align-items-center u-justify-content-center">
                    <img src={logo} alt="" className="c-image"/>
                </div>
                <div className="u-flex u-align-items-center u-justify-content-center">
                    <div className="u-block u-pad-left-right u-justify-content-center">
                        <div className="c-loader +thick" style={{display: "inline-block"}}></div>
                        <h2 className="c-heading +h2 u-marg-bottom u-pad-left-right u-pad-top-large" style={{display: "inline-block"}}>Loading</h2>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ClientLoading