import React, { useState } from "react";
import ChequeBatchList from "./ChequeBatchList";
import NewBatch from "./NewBatch";
import EditBatch from "./EditBatch";

export const CARD_BATCH_MANAGEMENT_STEP = 1;
export const NEW_BATCH_STEP = 2;
export const EDIT_BATCH_STEP = 3;

const ChequeBatchManagement = () => {
  const [currentStep, setCurrentStep] = useState<number>(
    CARD_BATCH_MANAGEMENT_STEP
  );
  const [existingBatchDetails, setExistingBatchDetails] = useState<any>([]);

  const changeStep = (step: number) => {
    setCurrentStep(step);
  };

  const changeBatch = (existingBatch: any) => {
    setExistingBatchDetails(existingBatch);
  };

  switch (currentStep) {
    case CARD_BATCH_MANAGEMENT_STEP:
      return (
        <ChequeBatchList changeStep={changeStep} changeBatch={changeBatch} />
      );
    case NEW_BATCH_STEP:
      return <NewBatch changeStep={changeStep} changeBatch={changeBatch} />;
    case EDIT_BATCH_STEP:
      return (
        <EditBatch
          changeStep={changeStep}
          batch={existingBatchDetails.batch}
          payments={existingBatchDetails.batchPayments}
        />
      );
    default:
      return <ChequeBatchList />;
  }
};
export default ChequeBatchManagement;
