import React, { useCallback, useContext, useEffect, useState } from "react";
import BatchingService from "./services/BatchingService";
import { AppLayout, Border, Button, GridLayout } from "morse-css";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { saveAs } from "file-saver";
import LoginContext from "../core/contexts/LoginContext";
import User from "../../../shared/entities/User";
import PagedList from "./PagedList";
import usePagedList from "./usePagedList";
import ErrorHandler from "../core/ErrorHandler";
import { NEW_BATCH_STEP, EDIT_BATCH_STEP } from "./ChequeBatchManagement";

const ChequeBatchList = (props: any) => {
  let batchRows: string[] | any;

  dayjs.extend(LocalizedFormat);
  const loginState = useContext(LoginContext);
  const [errorCode, setErrorCode] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const { items, numberOfItems, numberOfPages, setPage, page } = usePagedList(
    {
      getItems: async (offset: number, limit: number) => {
        return new BatchingService().getChequeBatchList(offset, limit);
      },
    },
    10
  );

  useEffect(() => {
    setPage(1);
  }, []);

  batchRows = items.map((item) => {
    const downloadBatch = async (event) => {
      try {
        if (item.id) {
          const csvData = await new BatchingService().getChequeCsv(item.id);
          const csvBlob = new Blob([csvData]);
          saveAs(csvBlob, "Cheque-Batch " + item.batchNumber + ".csv");
        }
      } catch (e) {
        if (!e.statusCode) {
          setErrorCode(500);
          setErrorMessage("A problem has occurred with our server.");
        } else {
          setErrorCode(e.statusCode);
          setErrorMessage(e.message);
        }
      }
    };
    return (
      <tr key={item.id}>
        <td>{item?.id}</td>
        <td>{item?.batchNumber}</td>
        <td>{dayjs(item?.openedDate).format("DD-MMM-YY")}</td>
        <td>{dayjs(item?.batchDate).format("DD-MMM-YY")}</td>
        <td>{item?.numberOfCheques}</td>
        <td>{item.username}</td>
        <td>{item.batchStatus}</td>
        <td align="right">
          &pound;
          {item.batchTotal.toLocaleString("en-UK", {
            minimumFractionDigits: 2,
          })}
        </td>
        <td>
          {item.batchStatus === "Started" && (
            <Button
              color={Button.Color.Secondary}
              size={Button.Size.Small}
              onClick={async (e) => {
                e.preventDefault();
                if (item.id) {
                  props.changeBatch(
                    await new BatchingService().getBatchDetails(item.id)
                  );
                  props.changeStep(EDIT_BATCH_STEP);
                }
              }}
            >
              Edit
            </Button>
          )}
        </td>
        <td>
          {(loginState.user as User).staffRoles.includes("batch-download") && (
            <Button size={Button.Size.Small} onClick={downloadBatch}>
              Download
            </Button>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      {errorMessage ? (
        <ErrorHandler statusCode={errorCode} message={errorMessage} />
      ) : (
        <>
          <AppLayout orientation={AppLayout.Orientation.Horizontal}>
            <AppLayout.Item position={AppLayout.Item.Position.Start}>
              <h2 className="c-heading +h2 u-marg-bottom">Cheque Batch List</h2>
            </AppLayout.Item>
            <AppLayout.Item position={AppLayout.Item.Position.End}>
              <>
                <Button
                  color={Button.Color.Positive}
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    props.changeStep(NEW_BATCH_STEP);
                  }}
                >
                  New Batch
                </Button>
              </>
            </AppLayout.Item>
          </AppLayout>

          <table className="c-table +lined">
            <thead>
              <tr>
                <th>Batch ID</th>
                <th>Batch No.</th>
                <th>Recorded Date.</th>
                <th>Received Date.</th>
                <th># Entries.</th>
                <th>Opened By</th>
                <th>Status</th>
                <th style={{ width: "50px" }}>Total</th>
                <th className="c-cell +tight"></th>
                <th className="c-cell +tight"></th>
              </tr>
            </thead>
            <tbody>
              <PagedList
                numberOfPages={numberOfPages}
                setPage={setPage}
                page={page}
              >
                {batchRows}
              </PagedList>
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default ChequeBatchList;
