import Demand from "../../../../shared/entities/Demand";
import Payment, { Source } from "../../../../shared/entities/Payment";
import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";
import { IDemand } from "../../../../shared/entities/IDemand";
import { DemandWithTotalPaid } from "../../../../shared/types/DemandWithTotalPaid";

class DemandService {
  async checkPayments(): Promise<number> {
    return await WebsiteConfiguration.current.apiTransport.get<any>(
      "payments/check",
      {}
    );
  }

  async getDemand(
    tenantRef: string,
    invoiceNumber: string
  ): Promise<DemandWithTotalPaid> {
    return await WebsiteConfiguration.current.apiTransport.get<any>(
      "demands/" +
        encodeURIComponent(tenantRef) +
        "/" +
        encodeURIComponent(invoiceNumber),
      {}
    );
  }

  async getDemands(searchTerm: string): Promise<IDemand[]> {
    return await WebsiteConfiguration.current.apiTransport.get<any>("demands", {
      searchTerm,
    });
  }

  async getDemandByTenantRef(searchTerm: string): Promise<IDemand> {
    return await WebsiteConfiguration.current.apiTransport.get<any>(
      "demands/tenantRef",
      { searchTerm }
    );
  }

  async updateDemand(demand: Demand): Promise<void> {
    await WebsiteConfiguration.current.apiTransport.put<any>(
      "demands/" +
        encodeURIComponent(demand.tenantRef) +
        "/" +
        encodeURIComponent(demand.invoiceNumber),
      demand
    );
  }

  async startPayment(
    demand: Demand,
    source: Source,
    email: string,
    electronicDemands: boolean,
    phoneNumber: string,
    paymentMethod: string,
    amount: number
  ): Promise<Payment> {
    return await WebsiteConfiguration.current.apiTransport.get<any>(
      "demands/" +
        encodeURIComponent(demand.tenantRef) +
        "/" +
        encodeURIComponent(demand.invoiceNumber) +
        "/payment",
      {
        source: source ? source : "Web",
        email,
        electronicDemands,
        phoneNumber,
        paymentMethod,
        amount,
      }
    );
  }

  async updatePayment(payment: Payment): Promise<Payment> {
    return await WebsiteConfiguration.current.apiTransport.put<any>(
      "payments/" + payment.id,
      payment
    );
  }

  async confirmPayment(payment: Payment, cardToken: string): Promise<Payment> {
    return await WebsiteConfiguration.current.apiTransport.post<any>(
      "payments/" + payment.id + "/confirm",
      {
        ...payment,
        cardToken,
      }
    );
  }

  async togglePayments(clientID: number): Promise<boolean> {
    return await WebsiteConfiguration.current.apiTransport.get<boolean>(
      "payments/toggle",
      { clientID }
    );
  }

  async makeSingleChequePayment(payment: Payment): Promise<any> {
    return await WebsiteConfiguration.current.apiTransport.post<any>(
      "payments/cheque-payment",
      { payment }
    );
  }

  async deletePayment(payment: Payment): Promise<Payment> {
    return await WebsiteConfiguration.current.apiTransport.delete<any>(
      "payments/delete/" + payment.id,
      {}
    );
  }
}

export default DemandService;
