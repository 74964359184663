import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import PayDemand from "./PayDemand";
import ClientContext from "../../src/core/contexts/ClientContext";
import { Button } from "morse-css/dist/lib/components/Button";
import logo from "../core/images/groundpay-grey.png";

const Welcome = (props: any) => {
  const createMarkup = (client): { __html: string } => {
    return { __html: client.faqText };
  };

  return (
    <>
      <ClientContext.Consumer>
        {(client) => (
          <Router>
            <Switch>
              {!client!.paymentsDisabled && (
                <Route path="/start" exact component={PayDemand} />
              )}
              <Route path="">
                <div className="l-wrap-box u-width-4">                  
                  {client && (<>
                  {client.paymentsDisabled ? (
                    <div className="u-flex u-align-items-center u-justify-content-center u-marg-top-bottom-large">
                      <div className="u-pad u-shadow-medium +quiet u-width-2 ">
                        <div className="u-flex u-align-items-center u-justify-content-center">
                          <img src={logo} alt="" className="c-image" />
                        </div>

                        <div className="u-pad-left-right u-justify-content-center">
                          <br></br>
                          <p>
                            We regret that the facility is currently
                            unavailable; subject to system requirements, the
                            facility is normally available from early May until
                            late October, and from early November until late
                            April each year. Please check back later to pay your
                            Ground Rent.
                          </p>
                          <br></br>
                          <p>
                            Please note that you will only be able to pay the
                            full amount as shown on your latest Ground Rent
                            demand - please have it available.
                          </p>
                          <br></br>
                          <p>
                            You will be sent an e-mail confirming your payment.
                          </p>
                          <br></br>
                          <p>
                            We look forward to receiving your Ground Rent by
                            on-line payment when the system is available.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (client.disabled ? (
                    <div className="u-flex u-align-items-center u-justify-content-center u-marg-top-bottom-large">
                      <div className="u-pad u-shadow-medium +quiet u-width-2 ">
                        <div className="u-flex u-align-items-center u-justify-content-center">
                          <img src={logo} alt="" className="c-image" />
                        </div>

                        <div className="u-pad-left-right u-justify-content-center">
                          <br></br>
                          <p>
                            We regret that this facility is no longer available. Please refer to the other payment options detailed on your ground rent demand.
                          </p>                          
                        </div>
                      </div>
                    </div>
                  ): (
                    <div>
                      <div className="s-cms-content u-marg-bottom-large">
                        <p>
                          Welcome to the {client && client.name} secure on-line
                          Ground Rent payment facility.
                        </p>
                        <p>
                          Please note that you will only be able to pay the full
                          amount as shown on your Ground Rent demand issued in
                          the last six months - please have it available.
                        </p>
                        <p>
                          If you do not wish to proceed at any stage, please
                          click on the 'Exit' button which will return you to
                          our main website. Please refer to the reverse of your
                          Ground Rent demand for alternative means of payment.
                        </p>
                        <p>
                          Thank you for using this facility - you will be sent
                          an e-mail confirming your payment.
                        </p>

                        <Link to="/start">
                          <Button color={Button.Color.Primary}>Continue</Button>
                        </Link>
                        <div dangerouslySetInnerHTML={createMarkup(client)} />
                      </div>
                    </div>
                  ))}
                  </>)}
                </div>
              </Route>
            </Switch>
          </Router>
        )}
      </ClientContext.Consumer>
    </>
  );
};

export default Welcome;
