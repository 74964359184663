import {Column, Entity, PrimaryGeneratedColumn} from "typeorm";

export enum BatchStatus {
    Started = 'Started',
    Closed = 'Closed'
}

@Entity()
export default class ChequeBatch {
    @PrimaryGeneratedColumn()
    public id?: number;

    @Column({type: "datetime", nullable: true})
    public batchDate?: Date;

    @Column({type: "datetime", nullable: true})
    public closedDate?: Date;

    @Column({type: "datetime", nullable: true})
    public openedDate?: Date;

    @Column({default: "", length: 25})
    public username?: string = "";

    @Column({default: "", length: 12})
    public batchNumber?: string = "";

    @Column({type: "integer", default: 0})
    public numberOfCheques: number = 0;

    @Column({type: "simple-enum", enum: BatchStatus})
    public batchStatus?: BatchStatus = BatchStatus.Started;

    @Column({type: "decimal", precision: 8, scale: 2})
    public batchTotal: number = 0;




}