import React, {useEffect, useState} from 'react';
import {Button, GridLayout} from "morse-css";
import useDebounce from "../receipts/hooks/useDebounce";
import useDemand from "../payment/hooks/useDemand";
import {IDemand} from "../../../shared/entities/IDemand";

const SearchDemands = (props: any) => {

    const [searchTerm, setSearchTerm] = useState(props.searchTerm);
    const [demands, setDemands] = useState<IDemand[]>();
    const { getDemandsBySearchTerm } = useDemand();
    const [showError, setShowError] = useState<boolean>(false);
    const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
    let noMatchMessage = "No Demands Matching \'" + searchTerm + "\'";
    let loadMessage = "Loading...";
    let searchMessage = "Search results for \'" + searchTerm + "\'";

    const populatePaymentRecords = async () => {
        try {
            setShowError(false);
            setLoadingFlag(true);
            await setDemands(await getDemandsBySearchTerm(searchTerm));
        } catch (e) {
            setShowError(true);
        }
    };

    useEffect( () => {
        if(searchTerm.length) {
            populatePaymentRecords();
        }
    }, [searchTerm]);

    useEffect(() => {
        if(demands && demands.length > 0) {
                setLoadingFlag(false);
        }
        searchTerm.length === 0 && setLoadingFlag(false);
        if (demands && demands.length === 0 && searchTerm.length > 0) {
            setLoadingFlag(false);
        }
    }, [demands, searchTerm]);

    const updateSearchTerm = useDebounce((value: string) => {
        if(value.length > 0) {
            setSearchTerm(value);
        } else {
            setDemands([]);
            setSearchTerm('');
        }
    }, 500);

    return (
        <div>
            <h2 className="c-heading +h2 u-marg-bottom">Search Demands</h2>
            <div className={""}>
                <h3 className="c-heading +h3 u-marg-bottom">Search by Tenant Ref, Invoice Number, name, email, phone number or property address:</h3>
                <GridLayout columns={2}>
                    <GridLayout.Item>
                        <input type="text" autoFocus className="c-input +text +adaptive +auto-fill" placeholder={"Search..."} onChange={ e => {
                            updateSearchTerm(e.target.value);
                            props.hideUpdateMessage();
                        }}
                        />
                    </GridLayout.Item>
                    <GridLayout.Item>
                        {props.showUpdateSuccess &&
                        <div className="c-alert u-fill-pos u-bold u-justify-content-center" style={{width: "250px", display: "inline-block"}}>
                            Demand Updated
                        </div>}
                    </GridLayout.Item>
                </GridLayout>
                <h2 className="c-heading +h2 u-marg-top">{searchTerm?.length > 0 ? searchMessage: ""}</h2>
            </div>

            <div>
                <table className="u-marg-top u-marg-bottom c-table +lined">
                    <thead>
                    <tr>
                        <th>Tenant Ref</th>
                        <th>Invoice Number</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Property Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {demands && demands.map((demand: IDemand) => {

                        return(
                            <tr key={demand.id}>
                                <td>{demand.tenantRef}</td>
                                <td>{demand.invoiceNumber}</td>
                                <td>{demand.name}</td>
                                <td>{demand.email}</td>
                                <td>{demand.phoneNumber}</td>
                                <td>{demand.propertyAddress}</td>
                                {/* allowOverlappingEvents on morse Button is stopping the button from throwing an update state on unmounted component warning - See SearchPayments.tsx */}
                                <td>{<Button color={Button.Color.Primary} allowOverlappingEvents={true} onClick={e =>{props.onDemandSelected(demand, searchTerm)}}>Edit Demand Record</Button>}</td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
                {showError && (<div><div className="c-alert u-fill-neg u-bold u-justify-content-center" style={{width: "250px"}}>
                    Server Error
                </div><p>Try again in a few seconds or contact support</p></div>)}
                <h2 className="-heading +h2 u-marg-bottom">{loadingFlag && !showError? loadMessage : ""}</h2>
                <h2 className="-heading +h2 u-marg-bottom">{demands && demands.length === 0 && searchTerm.length > 0 ? noMatchMessage : ""}</h2>
            </div>
        </div>
    )
};

export default SearchDemands;