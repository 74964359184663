import React, { useContext, useEffect, useState } from "react";
import { GridLayout, Meter } from "morse-css";
import { saveAs } from "file-saver";
import useEDemand, { SendingStages } from "./hooks/useEDemand";
import EDemandService from "./services/EDemandService";
import LoginContext from "../core/contexts/LoginContext";
import DemandService from "../payment/services/DemandService";
import ErrorHandler from "../core/ErrorHandler";

const SendEDemands = (props: any) => {
  const {
    stats,
    populateQueuedEDemands,
    currentState,
    sendEDemands,
    progressTotal,
    stopSendingEDemands,
    populateEDemandStats,
  } = useEDemand();
  const [queueButtonDisabled, setQueueButtonDisabled] =
    useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const loginState = useContext(LoginContext);
  const [paymentsDisabled, setPaymentsDisabled] = useState<any>(
    props.client.paymentsDisabled
  );
  const [email, setEmail] = useState<string>("");
  const [disabledPreview, setDisabledPreview] = useState<boolean>(true);
  const [previewMessage, setPreviewMessage] = useState("");
  const [uploadComplete, setUploadComplete] = useState<string>("");
  const [uploadMessageStyle, setUploadMessageStyle] = useState<string>(
    "u-marg-left u-color-pos"
  );
  const [displaySpinner, setDisplaySpinner] = useState<boolean>(false);
  
  const [amendUploadComplete, setAmendUploadComplete] = useState<string>("");
  const [amendUploadMessageStyle, setAmendUploadMessageStyle] = useState<string>(
    "u-marg-left u-color-pos"
  );

  const localeStringParams = {
    style: "currency",
    currency: "GBP",
  };

  useEffect(() => {
    !stats?.unqueued
      ? setQueueButtonDisabled(true)
      : setQueueButtonDisabled(false);
  }, [stats?.unqueued]);

  useEffect(() => {
    let emailValidation = new RegExp(
      "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
    );
    emailValidation.test(email)
      ? setDisabledPreview(false)
      : setDisabledPreview(true);
  }, [email]);

  const manageProgressBar = () => {
    if (progressTotal) {
      return ((progressTotal - stats.unsent) / progressTotal) * 100;
    } else {
      return 0;
    }
  };

  const togglePayments = async (clientID: number) => {
    try {
      let toggleState = await new DemandService().togglePayments(clientID);
      setPaymentsDisabled(toggleState);
    } catch (e) {
      if (!e.statusCode) {
        setErrorCode(500);
        setErrorMessage("A problem has occurred with our server.");
      } else {
        setErrorCode(e.statusCode);
        setErrorMessage(e.message);
      }
    }
  };

  const checkPayments = async () => {
    try {
      const count = await new DemandService().checkPayments();
      alert(count + " payment(s) incorrect");
    } catch (e) {}
  };

  const downloadEDemands = async () => {
    try {
      const csvUrl = await new EDemandService().getEDemandPreferences();
      window.location.href = csvUrl;
    } catch (e) {
      if (!e.statusCode) {
        setErrorCode(500);
        setErrorMessage("A problem has occurred with our server.");
      } else {
        setErrorCode(e.statusCode);
        setErrorMessage(e.message);
      }
    }
  };

  const downloadDemands = async () => {
    try {
      const csvUrl = await new EDemandService().getDemandPreferences();
      window.location.href = csvUrl;
    } catch (e: any) {
      if (!e.statusCode) {
        setErrorCode(500);
        setErrorMessage("A problem has occurred with our server.");
      } else {
        setErrorCode(e.statusCode);
        setErrorMessage(e.message);
      }
    }
  };

  const appendEDemandPrefs = async (csv) => {
    setDisplaySpinner(true);
    if (csv) {
      const url: string = await new EDemandService().getUploadUrl();

      const response = await fetch(url, {
        method: "PUT",
        body: csv,
      });

      let result: string = await new EDemandService().appendEDemands(url);
      alert(result[0]);

      setAmendUploadComplete(result[0]);
      result[1]
        ? setAmendUploadMessageStyle("u-marg-left u-color-neg")
        : setAmendUploadMessageStyle("u-marg-left u-color-pos");
      await populateEDemandStats();
      setDisplaySpinner(false);
    }
  };

  const uploadEDemandPrefs = async (csv) => {
    setDisplaySpinner(true);
    if (csv) {
      const url: string = await new EDemandService().getUploadUrl();

      const response = await fetch(url, {
        method: "PUT",
        body: csv,
      });

      let result: string = await new EDemandService().populateEDemands(url);
      alert(result[0]);
      setUploadComplete(result[0]);
      result[1]
        ? setUploadMessageStyle("u-marg-left u-color-neg")
        : setUploadMessageStyle("u-marg-left u-color-pos");
      await populateEDemandStats();
      setDisplaySpinner(false);
    }
  };

  const downloadUnQueuedEDemands = async () => {
    try {
      const csvData = await new EDemandService().getUnqueuedEDemands();
      const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      saveAs(csvBlob, "TotalEDemands.csv");
    } catch (e) {
      if (!e.statusCode) {
        setErrorCode(500);
        setErrorMessage("A problem has occurred with our server.");
      } else {
        setErrorCode(e.statusCode);
        setErrorMessage(e.message);
      }
    }
  };

  const sendEDemandPreview = async () => {
    const previewEmail = await new EDemandService().sendPreviewEmail(email);
    setPreviewMessage(previewEmail);
  };

  return (
    <>
      {errorMessage ? (
        <ErrorHandler statusCode={errorCode} message={errorMessage} />
      ) : (
        <>
          <GridLayout columns={1} className="+cols-2@m">
            <GridLayout.Item>
              {loginState.user?.staffRoles.includes("tricord") ? (
                <div>                  
                  <div>
                    <h2 className="c-heading +h2 u-marg-bottom">Data Export</h2>
                    <p className="u-marg-bottom">
                      Export the existing demand data for modification and
                      re-upload
                    </p>
                    <button
                      className="c-button u-pad-left u-marg"
                      onClick={downloadDemands}
                    >
                      EXPORT DEMANDS
                    </button>

                    <button
                      className="c-button u-pad-left"
                      onClick={downloadEDemands}
                    >
                      DOWNLOAD E-DEMANDS
                    </button>
                    <hr className="u-marg-top"></hr>
                  </div>
                  <div>
                    <h2 className="c-heading +h2 u-marg-bottom">Import Data</h2>                    
                    <p className="u-marg-bottom">
                      Upload data to <strong style={{color: "red"}}>replace</strong> the demand data in
                      it's entirety.
                    </p>
                    <p className="u-marg-bottom">
                      Please note the holdEmail flag is <b>not</b> a means to control the tenant's 
                      preference but a way to stop them receiving a demand email in special circumstances.
                      This does not update their stored preference in any way and only affects the current
                      season.
                    </p>
                    {displaySpinner ? (
                      <div
                        className="c-loader +thick"
                        style={{ display: "inline-block" }}
                      ></div>
                    ) : (
                      <div>
                        <input
                          type="file"
                          accept=".csv"
                          onChange={(e) => {
                            e.preventDefault();
                            if (e.target.files![0].size > 10000000) {
                              setUploadMessageStyle("u-marg-left u-color-neg");
                              setUploadComplete(
                                "File must be under 10MB in size"
                              );
                            } else {
                              uploadEDemandPrefs(e.target.files![0]);
                            }
                          }}
                        />
                      </div>
                    )}
                    <p className={uploadMessageStyle}>{uploadComplete}</p>

                    <hr className="u-marg-top"></hr>
                  </div>
                  <div>
                    <h2 className="c-heading +h2 u-marg-bottom">Append Data</h2>
                    <p className="u-marg-bottom">
                      Upload data to <strong style={{color: "red"}}>add to</strong> the demand data already uploaded
                    </p>
                    {displaySpinner ? (
                      <div
                        className="c-loader +thick"
                        style={{ display: "inline-block" }}
                      ></div>
                    ) : (
                      <div>
                        <input
                          type="file"
                          accept=".csv"
                          onChange={(e) => {
                            e.preventDefault();
                            if (e.target.files![0].size > 10000000) {
                              setAmendUploadMessageStyle("u-marg-left u-color-neg");
                              setAmendUploadComplete(
                                "File must be under 10MB in size"
                              );
                            } else {
                              appendEDemandPrefs(e.target.files![0]);
                            }
                          }}
                        />
                      </div>
                    )}
                    <p className={amendUploadMessageStyle}>{amendUploadComplete}</p>

                    <hr className="u-marg-top"></hr>
                  </div>
                  <div>
                    <h2 className="c-heading +h2 u-marg-bottom">
                      Preview eDemand
                    </h2>
                    <p className="u-marg-bottom">
                      This will send the first eDemand to the nominated email
                      address.
                    </p>
                    <input
                      type="email"
                      placeholder={props.client.fromEmailAddress}
                      style={{ width: "50%" }}
                      onChange={(e) => {
                        setPreviewMessage("");
                        setEmail(e.target.value);
                      }}
                    />
                    <div className="u-marg-top">
                      <label className="u=marg-left">
                        <button
                          disabled={disabledPreview}
                          className="c-button u-pad-left +warn"
                          onClick={(e) => {
                            sendEDemandPreview();
                          }}
                        >
                          PREVIEW
                        </button>
                        {previewMessage}
                      </label>
                    </div>

                    <hr className="u-marg-top"></hr>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <h2 className="c-heading +h2 u-marg-bottom">Queue and Send</h2>

              <p>
                There are
                {stats.unqueued ? (
                  <a
                    onClick={downloadUnQueuedEDemands}
                    style={{ pointerEvents: "auto" }}
                  >
                    {" "}
                    {stats.unqueued} demands{" "}
                  </a>
                ) : (
                  <a
                    onClick={downloadUnQueuedEDemands}
                    style={{ pointerEvents: "none" }}
                  >
                    {" "}
                    {stats.unqueued} demands{" "}
                  </a>
                )}
                available to queue for eDemands. Note that if you have already
                started receiving payments - this may include some paper demands
                that have paid and signed up for future eDemands.
              </p>

              <button
                className="c-button +1 u-marg-top"
                onClick={async () => {
                  await populateQueuedEDemands();
                }}
                disabled={queueButtonDisabled}
              >
                POPULATE QUEUE
              </button>
              <p className="u-marg-top">
                There are {stats.unsent} emails in the queue to send. Click
                send to start sending in batches of 15. You must leave this
                browser open for sending to complete.
              </p>
              {currentState === SendingStages.Sending ? (
                <button
                  className="c-button +neg u-marg-top"
                  onClick={async () => {
                    await stopSendingEDemands();
                  }}
                >
                  STOP SENDING
                </button>
              ) : (
                <button
                  className="c-button +pos u-marg-top"
                  onClick={async () => {
                    await sendEDemands();
                  }}
                >
                  START SENDING
                </button>
              )}

              <Meter
                className="c-meter u-pill u-color-pos u-marg-top"
                min={0}
                max={100}
                value={manageProgressBar()}
                low={25}
                optimum={50}
                high={75}
              >
                <div>
                  <span style={{ width: manageProgressBar() + "%" }}></span>
                </div>
              </Meter>
            </GridLayout.Item>
            <GridLayout.Item className="cc-info-module">
              <div className="l-wrap">
                <div className="u-font-small u-pad u-pad--heavy@m">
                  <h2 className="c-heading +h2 u-marg-bottom">Demand Stats</h2>
                  <table style={{ width: "70%" }}>
                    <tbody>
                      <tr>
                        <td>Total Demands:</td>
                        <td style={{ textAlign: "right" }}>{stats.totalDemands}</td>
                      </tr>
                      <tr>
                        <td>Total Ground Rent To Pay:</td>
                        <td style={{ textAlign: "right" }}>
                          {stats.totalGroundRentToPay.toLocaleString(
                            "en-GB",
                            localeStringParams
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="u-pad-bottom"></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Total Paid:</td>
                        <td style={{ textAlign: "right" }}>{stats.totalPaid}</td>
                      </tr>
                      <tr>
                        <td>Total Ground Rent Paid:</td>
                        <td style={{ textAlign: "right" }}>
                          {stats.totalGroundRentPaid.toLocaleString(
                            "en-GB",
                            localeStringParams
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="u-pad-bottom"></td>
                        <td></td>
                      </tr>                      
                      <tr>
                        <td>Total 'Held' from edemands:</td>
                        <td style={{ textAlign: "right" }}>
                          {stats.totalHeldEDemands}
                        </td>
                      </tr>
                      <tr>
                        <td className="u-pad-bottom"></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Total eDemands sent in season:</td>
                        <td style={{ textAlign: "right" }}>
                          {stats.totaleDemandsSent}
                        </td>
                      </tr>
                      <tr>
                        <td>Total eDemands in preference table:</td>
                        <td style={{ textAlign: "right" }}>
                          {stats.totaleDemandsAsPreference}
                        </td>
                      </tr>
                      <tr>
                        <td className="u-pad-bottom"></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Total Records in Blocked Table:</td>
                        <td style={{ textAlign: "right" }}>
                          {stats.totalBlocked}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr></hr>
                <div className="u-font-small u-pad u-pad--heavy@m">
                  <h2 className="c-heading +h2 u-marg-bottom">
                    Toggle Website
                  </h2>
                  <div>
                    {!paymentsDisabled ? (
                      <div>
                        <h2 className="u-marg-left u-color-pos">
                          Payments are LIVE
                        </h2>
                        <button
                          className="c-button +neg u-marg-top"
                          onClick={async () => {
                            let toggle = confirm(
                              "Are you sure you want to stop all payments incoming to the site?"
                            );
                            if (toggle) {
                              await togglePayments(props.client!.id!);
                            }
                          }}
                        >
                          TURN OFF
                        </button>
                      </div>
                    ) : (
                      <div>
                        <h2 className="u-marg-left u-color-neg">
                          Payments are STOPPED
                        </h2>
                        <button
                          className="c-button +pos u-marg-top"
                          onClick={async () => {
                            await togglePayments(props.client!.id!);
                          }}
                        >
                          TURN ON
                        </button>
                      </div>
                    )}                    
                  </div>                  
                </div>
                <hr></hr>
                  <div className="u-font-small u-pad u-pad--heavy@m">
                    <h2 className="c-heading +h2 u-marg-bottom">
                      Payment Check
                    </h2>
                    <p className="u-marg-bottom">
                      Checks for any failed payments that might be recorded as successful in Stripe.
                    </p>
                    <button
                      className="c-button u-pad-left"
                      onClick={checkPayments}
                    >
                      CHECK PAYMENTS
                    </button>
                    <hr className="u-marg-top"></hr>
                  </div>
              </div>
            </GridLayout.Item>
          </GridLayout>
        </>
      )}
    </>
  );
};

export default SendEDemands;
