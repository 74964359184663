import { useState } from "react";
import { container } from "tsyringe";
import Demand from "../../../../shared/entities/Demand";
import DemandService from "../services/DemandService";
import { useAsyncAction } from "../../core/hooks/useAsyncAction";
import { DemandWithTotalPaid } from "../../../../shared/types/DemandWithTotalPaid";
import Payment from "../../../../shared/entities/Payment";

const useDemand = () => {
    const demandService = new DemandService();

    const loadDemand = useAsyncAction(0);
    const editDemand = useAsyncAction(50);

    const getDemandDetails = async (tenantRef: string, invoiceNumber: string) => {
        const demandDetails = await demandService.getDemand(tenantRef, invoiceNumber);
        
        setDemand(demandDetails);
        
        return demandDetails;
    };

    const getDemandsBySearchTerm = async (searchTerm: string) => {
        const demandDetails = await demandService.getDemands(searchTerm);

        return demandDetails;
    };

    const getDemandByTenantRef = async (searchTerm: string) => {
        const demandDetails = await demandService.getDemandByTenantRef(searchTerm);

        return demandDetails;
    }

    const initialDemand: DemandWithTotalPaid = {
        ...new Demand(),
        totalPaid: 0
    };

    const[demand, setDemand] = useState(initialDemand);

    return {demand, getDemandDetails, getDemandsBySearchTerm, getDemandByTenantRef}
};

export default useDemand;