import React, { useState, useEffect, useContext } from "react";
import AuthService from "./services/AuthService";
import LoginContext from "../core/contexts/LoginContext";
import ErrorHandler from "../core/ErrorHandler";

const authService = new AuthService();

const Login = () => {
    const COGNITO_LOGIN = "https://groundpay.auth.eu-west-2.amazoncognito.com/login?client_id=5hph9bupkiiemsvl9du9u4i0q3&response_type=token&scope=email+openid+phone&redirect_uri=" + window.location.href;
    const loginState = useContext(LoginContext);
    const [errorHandlerShown, setErrorHandlerShown] = useState(false);
    const [redirected, setRedirected] = useState<boolean>(false);
    const [authResponse, setAuthResponse] = useState<{
      idToken: string;
      accessToken: string;
    }>();
        
    useEffect(() => {
        const urlParts = window.location.href.split("#", 2);
        if (urlParts.length === 2 && urlParts[1].includes("id_token")) {
          const urlParams = new URLSearchParams(urlParts[1]);
          setAuthResponse({
            idToken: urlParams.get("id_token") || "",
            accessToken: urlParams.get("access_token") || ""
          });
        } else {
          if (!redirected && !loginState.isAuthenticated()) {
            setRedirected(true);
            setTimeout(() => {
              const redirectUrl = new URL(COGNITO_LOGIN);
              window.location.href = redirectUrl.toString();
            }, 200);
          }
        }
      }, [redirected]);
    
      useEffect(() => {
        if (!authResponse || !authResponse.idToken || !authResponse.accessToken)
          return;

        authService.login(authResponse).then(token => {
          loginState.changeToken(token);
        }).catch( (e) => {
            setErrorHandlerShown(true);
        });
      }, [authResponse]);    

      return <>{errorHandlerShown && <ErrorHandler message = 'Permission Denied'/>}</>;
};

export default Login;