import React from 'react';
import ClientContext from '../../contexts/ClientContext';
import logo from "../../images/groundpay-grey.png";
import cardLogos from "../../images/card_logos.gif";

const Footer = () =>{

    return (
        <ClientContext.Consumer>
            {client => 
                <div className="g-footer">
                    <div className="u-pull-left u-2/3">
                        <div className="o-flex o-flex--align-middle o-flex--flex-start">
                            <img src={'/' + logo} />
                            <img src={'/' + cardLogos} style={{ marginLeft: "1em" }} />
                        </div>
                    </div>
                    <div className="u-font-xs u-translucent u-align-right u-pull-right u-1/3">
                        <p>{client?.clientAddress.replace(/\n/g, ', ')}</p>
                    </div>
                </div>
            }
        </ClientContext.Consumer>
    )
};

export default Footer;