import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import useDemand from "./hooks/useDemand";
import Step from "../../src/core/components/wizard/Step";
import PayeeContactDetailsStep from "./payment-wizard/PayeeContactDetailsStep";
import PaymentStep from "./payment-wizard/PaymentStep";
import Wizard from "../core/components/wizard/Wizard";
import Demand from "../../../shared/entities/Demand";
import DemandContext from "./contexts/DemandContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentCompleteStep from "./payment-wizard/PaymentCompleteStep";
import { GridLayout, Border } from "morse-css";
import Client from "../../../shared/entities/Client";
import ClientContext from "../core/contexts/ClientContext";
import LoginContext from "../core/contexts/LoginContext";
import AuthService from "../admin/services/AuthService";

type PayDemandProps = {
  moto?: boolean;
};

const PayDemand = (props: PayDemandProps) => {
  const loginState = useContext(LoginContext);
  const queryString = require("query-string");
  const [amountToPay, setAmountToPay] = useState<number>(0);

  const parsed = queryString.parse(window.location.search);

  const [paymentId, setPaymentId] = useState<number>(0);

  const { register, handleSubmit, watch, errors } = useForm<
    Demand & { source: string }
  >({
    defaultValues: {
      tenantRef: parsed.t,
      source:
        loginState.isAuthenticated() &&
        loginState.user?.staffRoles.includes("tricord")
          ? "Telephone"
          : "",
    },
  });
  const [formState, setFormState] =
    useState<Demand & { paymentMethod?: string; source?: string }>();
  const { demand, getDemandDetails } = useDemand();

  const onSubmit = async (data: any) => {
    try {
      if (props.moto) {
        try {
          // We simply need to call checkLogin. If checking the current login token fails,
          // the underlying Axios transport will "log us out" and loginState will register
          // that we are no longer logged in.
          await new AuthService().checkLogin();
        } catch (error) {
          alert("Sorry, your login has expired and must be reverified");
          window.location.href = "/admin";
          return;
        }
      }

      const demand = await getDemandDetails(data.tenantRef, data.invoiceNumber);
      setFormState({ ...demand, source: data.source });
      setAmountToPay(Math.max(demand.groundRentToPay - demand.totalPaid, 0));
    } catch (e) {
      alert("Please check your Payee Ref and Invoice Number are correct");
    }
  };

  const pushDemandState = (data: any) => {
    setFormState({ ...formState, ...data });
  };

  const client: Client = useContext(ClientContext) as Client;
  const stripePromise = loadStripe(client.stripePublicKey);

  return (
    <div>
      {demand.tenantRef == "" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {props.moto && (
              <div className="c-alert u-marg-bottom-large">
                <p className="u-flex-grow-1">
                  <b>Do you require a receipt?</b>
                  <br />
                  If Yes, do you have an email address?
                  <br />
                  If Yes, continue
                  <br />
                  <i>
                    <b>If No, please post your demand into us with an SAE</b>
                  </i>
                </p>
              </div>
            )}

            <GridLayout columns={1} className="+cols-2@m">
              <GridLayout.Item>
                <div className="l-wrap u-width-2">
                  <div className="u-pad u-pad--heavy@m">
                    <div className="c-input-group">
                      <input
                        id="tenantRef"
                        className="c-input +text +fill +no-border"
                        type="text"
                        name="tenantRef"
                        ref={register}
                        required
                        placeholder={client.tenantRefTerm}
                        maxLength={15}
                      />

                      <span
                        id="TenantRefValidation"
                        className="u-micro u-red"
                      ></span>

                      <input
                        id="invoiceNum"
                        type="text"
                        name="invoiceNumber"
                        ref={register}
                        required
                        className="c-input +text +fill +no-border"
                        placeholder="Invoice Number"
                        maxLength={15}
                      />

                      {props.moto && (
                        <select
                          id="source"
                          name="source"
                          ref={register}
                          required
                          className="c-input +select +fill +no-border"
                        >
                          <option value="">Source</option>
                          <option>Telephone</option>
                          <option>Walk in</option>
                        </select>
                      )}

                      <span
                        id="InvoiceNumberValidation"
                        className="u-micro u-red"
                      ></span>
                    </div>
                    <p className="u-font-small u-translucent u-marg-top-bottom">
                      Please ensure that your Ground Rent Demand showing the
                      invoice number was issued not more than{" "}
                      <b>six months ago</b>.
                    </p>
                    <button
                      type="submit"
                      className="c-button +1 +long pg-button"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </GridLayout.Item>
              <GridLayout.Item className="cc-info-module">
                <div className="l-wrap u-width-2">
                  <div
                    className="u-font-small u-pad u-pad--heavy@m"
                    dangerouslySetInnerHTML={{
                      __html: client.tenantRefDescription,
                    }}
                  ></div>
                </div>
              </GridLayout.Item>
            </GridLayout>
          </div>
        </form>
      ) : (
        <div>
          <DemandContext.Provider value={demand}>
            <Elements stripe={stripePromise}>
              <Wizard defaultStep={"PayeeContactDetailsStep"}>
                <Step name={"PayeeContactDetailsStep"}>
                  <PayeeContactDetailsStep
                    updateAmountToPay={setAmountToPay}
                    amountToPay={amountToPay}
                    submittedData={formState}
                    submitData={pushDemandState}
                    demand={demand}
                    moto={props.moto}
                  />
                </Step>
                <Step name={"ContinueToPaymentStep"}>
                  <PaymentStep
                    amountToPay={amountToPay}
                    demand={demand}
                    moto={props.moto}
                    submittedData={formState}
                    setPaymentId={setPaymentId}
                  />
                </Step>
                <Step name="PaymentCompleteStep">
                  <PaymentCompleteStep
                    submittedData={formState}
                    moto={props.moto}
                    paymentId={paymentId}
                  />
                </Step>
              </Wizard>
            </Elements>
          </DemandContext.Provider>
        </div>
      )}
    </div>
  );
};

export default PayDemand;
