import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import Client from "../../../../shared/entities/Client";
import RentService from "../services/RentService";
import RentDemandContext from "../contexts/RentDemandContext";
import RentDemand from "../../../../shared/entities/Demand";
import { calculateSurcharge } from "../../../../shared/util/surchargeHelper";
import LoginContext from "../../core/contexts/LoginContext";
import ClientContext from "../../core/contexts/ClientContext";
import { Link } from "react-router-dom";

const PayeeContactDetailsStep = (props: any & { demand: RentDemand }) => {
  const loginState = useContext(LoginContext);

  const { register, handleSubmit, watch, errors } = useForm<RentDemand>();

  const onSubmit = async (data) => {
    if (loginState.user) {
      if (loginState.user.staffRoles.includes("tricord")) {
        if (!confirm("Have you paused call recording?")) {
          return;
        }
      }
    }

    props.submitData && props.submitData(data);
    props.changeStep && props.changeStep("ContinueToPaymentStep");
  };

  const onChangePaymentMethod = (e) => {
    props.submitData({ ...props.submittedData, paymentMethod: e.target.value });
  };

  const onAmountChanged = (e) => {
    props.submitData({ ...props.submittedData, amount: e.target.value });
  };

  const surcharge = calculateSurcharge(
    props.submittedData.amount,
    props.submittedData.paymentMethod
  );
  const total = props.submittedData.amount
    ? parseFloat(props.submittedData.amount) + surcharge
    : 0;

  // Step count is just a visual indicator
  let stepCount = 1;

  const client = useContext(ClientContext);

  return (
    <RentDemandContext.Consumer>
      {(demand) => {
        if (demand.isBlocked) {
          return (
            <>
              <div className="c-alert +urgent">
                <p>
                  This Tenant has been Blocked from online payment
                  <br />
                  <br />
                  Refer to Qube for Reason e.g. Legal Action
                </p>
              </div>
              <p style={{ textAlign: "center" }}>
                <a href="/admin/rent">
                  <span className="c-button +1">Continue</span>
                </a>
              </p>
            </>
          );
        }

        return (
          <>
            {demand && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="o-wrap o-wrap--narrow">
                  <p className="u-marg-bottom">
                    Please check the following information from your latest
                    Ground Rent demand:
                  </p>

                  <div className="cc-module">
                    <div className="cc-module__header">
                      <h3 className="o-flex__item u-white">
                        {stepCount++}. Invoice (Invoice number -{" "}
                        {demand ? demand.invoiceNumber : ""})
                      </h3>
                    </div>
                  </div>

                  <div className="cc-module">
                    <div className="cc-module__header">
                      <h3 className="u-white">
                        {stepCount++}. Property (Tenant Ref -{" "}
                        {demand ? demand.tenantRef : ""})
                      </h3>
                    </div>
                    <div className="cc-module__body">
                      <p>
                        Tenancy: <b>{demand ? demand.propertyAddress : ""}</b>
                      </p>
                    </div>
                  </div>

                  <div className="cc-module">
                    <div className="cc-module__header">
                      <h3 className="u-white">
                        {stepCount++}. Contact Details
                      </h3>
                    </div>
                    <div className="cc-module__body">
                      <div className="c-fields +equal +inline">
                        <div className="c-field">
                          <label className="c-label" htmlFor="phone">
                            Email Address<em id="EmailValidation">*</em>
                          </label>
                          <input
                            className="c-input +text +fill"
                            id="phone"
                            autoFocus
                            type="email"
                            placeholder="email@email.com"
                            name="email"
                            defaultValue={demand.email}
                            ref={register({
                              required: true,
                              pattern: /^\S+@\S+$/i,
                            })}
                          />
                        </div>
                        <div className="c-field">
                          <label className="c-label" htmlFor="phone">
                            Daytime Telephone
                          </label>
                          <input
                            className="c-input +text +fill"
                            id="phone"
                            type="phone"
                            placeholder="0281234567"
                            name="phoneNumber"
                            defaultValue={demand.phoneNumber}
                            ref={register}
                          />
                          <span className="c-field__helper u-font-small u-translucent">
                            (Only for use in case of problems with Card Payment)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {client?.enableSurcharges && (
                    <div className="cc-module">
                      <div className="cc-module__header">
                        <h3 className="u-white">{stepCount++}. Payment</h3>
                      </div>
                      <div className="cc-module__body">
                        <table
                          style={{ width: "auto" }}
                          className="c-table +grid u-marg-bottom"
                        >
                          <tr>
                            <td>Payment Method:</td>
                            <td className="u-align-right">
                              <select
                                id="paymentMethod"
                                name="paymentMethod"
                                ref={register({ required: true })}
                                onChange={onChangePaymentMethod}
                                className="c-input +tight +no-border +select"
                              >
                                <option value="">Please Select</option>
                                <option>Personal Card</option>
                                <option>Business Card</option>
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>Amount To Pay:</td>
                            <td style={{ textAlign: "right" }}>
                              {" "}
                              <b>&pound;</b>
                              <input
                                className="c-input +tight +text u-marg-left"
                                id="amount"
                                type="text"
                                name="amount"
                                ref={register}
                                onChange={onAmountChanged}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Surcharge:</td>
                            <td style={{ textAlign: "right" }}>
                              &pound;
                              <span id="surcharge">
                                {surcharge.toLocaleString("en-UK", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </td>
                          </tr>
                          <tr className="u-bold">
                            <td>Payment Total:</td>
                            <td style={{ textAlign: "right" }}>
                              &pound;
                              <span id="total">
                                {total.toLocaleString("en-UK", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </td>
                          </tr>
                        </table>
                        <p className="u-font-small">
                          Please note:
                          <br />A transaction charge of <b>2.5%</b> will be made
                          for Business Card payments. No transaction charge will
                          made for Personal Card payments
                        </p>
                      </div>
                    </div>
                  )}

                  <button type="submit" className="c-button +1 +large">
                    Continue
                  </button>
                </div>
              </form>
            )}
          </>
        );
      }}
    </RentDemandContext.Consumer>
  );
};

export default PayeeContactDetailsStep;
