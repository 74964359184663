import React, {useEffect, useState} from "react";
import {Button} from "morse-css";
import DemandService from "../../payment/services/DemandService";
import Payment from "../../../../shared/entities/Payment";

const BatchTable = (props: any) => {

    const deleteData = (item) => {
            new DemandService().deletePayment(item as Payment);
    };

        return (
            <table className="u-marg-top u-marg-bottom styled-table">
                <thead>
                <tr>
                    <th>Tenant Ref</th>
                    <th>Invoice Number</th>
                    <th>Property Name</th>
                    <th>Due</th>
                    <th>Paid</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {props.items.map(item => {
                    return (
                        <tr key={item.id}>
                            <td>{item.tenantRef}</td>
                            <td>{item.invoiceNumber}</td>
                            <td>{item.propertyAddress}</td>
                            <td>&pound;{item.due.toLocaleString('en-UK', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</td>
                            <td>&pound;{item.amount.toLocaleString('en-UK', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</td>
                            <td><Button size={Button.Size.Small} onClick={(event) => {
                                event.preventDefault();
                                props.removeCheque(item);
                                deleteData(item);
                            }}>Remove</Button>
                            </td>

                        </tr>
                    );
                })}
                </tbody>
            </table>

        )

};

export default BatchTable;