import React, { useContext, useEffect } from "react";
import { useAsyncAction } from "../core/hooks/useAsyncAction";
import LoginContext from "../core/contexts/LoginContext";
import AuthService from "./services/AuthService";
import { Link } from "react-router-dom";
import { Button } from "morse-css";
import User from "../../../shared/entities/User";
import ClientContext from "../core/contexts/ClientContext";

const Menu = () => {
  const loginState = useContext(LoginContext);
  const confirmingLoginAction = useAsyncAction();

  const client = useContext(ClientContext);
  
  if (loginState.isAuthenticated()){
    useEffect(() => {
      confirmingLoginAction.trigger(async () => {
        try {
          // We simply need to call checkLogin. If checking the current login token fails,
          // the underlying Axios transport will "log us out" and loginState will register
          // that we are no longer logged in.
          new AuthService().checkLogin();
        } catch (error){        
        }
      });
    }, [] );
  }
    return <>
    {confirmingLoginAction.state.slow && <p>Checking Login Details</p>}
    {confirmingLoginAction.state.complete &&                 
      <div className="l-wrap u-width-3 u-pad-top-large cc-menu">
      <ul>                  
        {client?.enableMoto && <li className="u-marg"><Link to="/admin/moto"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Primary}>Record a Groundrent Payment</Button></Link></li>}
        {client?.enableCheques && (loginState.user as User).staffRoles.includes('cheques') && <li className="u-marg"><Link to="/admin/cheque-batches"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Primary}>Record a Cheque Payment</Button></Link></li>}
        {client?.enableRent && <li className="u-marg"><Link to="/admin/rent"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Primary}>Record a Rent payment</Button></Link></li>}
        {client?.enableResend && <li className="u-marg"><Link to="/admin/receipts"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Quiet}>Resend Receipts</Button></Link></li>}
        {(loginState.user as User).staffRoles.includes('search-demands') && <li className="u-marg"><Link to="/admin/demands"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Quiet}>Update Demand Record</Button></Link></li>}
        {(loginState.user as User).staffRoles.includes('blocked-accounts') && <li className="u-marg"><Link to="/admin/blocked"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Quiet}>Block Demand Payments</Button></Link></li>}
        {(loginState.user as User).staffRoles.includes('batching') && <li className="u-marg"><Link to="/admin/batches"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Quiet}>Batch Management</Button></Link></li>}        
        {client?.enableEdemands && (loginState.user as User).staffRoles.includes('edemands') && <li className="u-marg"><Link to="/admin/edemands"><Button shape={Button.Shape.Long} size={Button.Size.Large} color={Button.Color.Quiet}>Data Loading</Button></Link></li>}                
      </ul>
      </div>
    }
  </>
}

export default Menu;
