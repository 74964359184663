import React, { useContext } from "react";
import { IClient } from "../clients/IClient";
import ClientContext from "../../contexts/ClientContext";
import { Button, AppLayout } from "morse-css";
import LoginContext from "../../contexts/LoginContext";
import { useHistory } from "react-router-dom";
import User from "../../../../../shared/entities/User";

const Header = (props: IClient) => {
  const loginState = useContext(LoginContext);
  const history = useHistory();

  const logout = () => {
    loginState.logout();
    history.push("/");
  };

  return (
    <ClientContext.Consumer>
      {(client) => (
        <AppLayout orientation={AppLayout.Orientation.Horizontal}>
          <AppLayout.Item position={AppLayout.Item.Position.Start}>
            <img src={client?.logo} />
          </AppLayout.Item>
          <AppLayout.Item position={AppLayout.Item.Position.End}>
            {loginState.isAuthenticated() ? (
              <>
                <span className="u-marg">
                  Logged in as <b>{(loginState.user as User).username}</b>
                </span>
                <a href="/admin">
                  <Button color={Button.Color.Primary}>Menu</Button>
                </a>
                &nbsp;
                <Button onClick={logout}>Logout</Button>
              </>
            ) : (
              ""
            )}
          </AppLayout.Item>
        </AppLayout>
      )}
    </ClientContext.Consumer>
  );
};

export default Header;
