import WebsiteConfiguration from "../../../configurations/WebsiteConfiguration";
import {IElectronicDemand} from "../../core/components/edemands/IElectronicDemand";

class EDemandService {

    async getEDemandStats() : Promise<IElectronicDemand> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/queue", {});
    }

    async populateQueuedEDemands() : Promise<IElectronicDemand> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/queue", {});
    }

    async sendBatchedEDemands(): Promise<IElectronicDemand> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/queue/batches", {});
    }

    async getEDemandPreferences(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/edemands", {});
    }

    async getDemandPreferences(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/preferences", {});
    }

    async getUnqueuedEDemands(): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.get<any>("edemands/unqueued", {});
    }

    async getUploadUrl(): Promise<string> {
        return await WebsiteConfiguration.current.apiTransport.get<string>("edemands/upload-url", {});
    }

    async populateEDemands(url: string): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/upload", {url});
    }

    async appendEDemands(url: string): Promise<any> {
        return await WebsiteConfiguration.current.apiTransport.post<any>("edemands/upload/append", {url});
    }

    async sendPreviewEmail(email): Promise<string> {
        return await WebsiteConfiguration.current.apiTransport.post<string>("edemands/sendpreview", {email})
    }
}

export default EDemandService;