import React, {useEffect, useState} from "react";
import SearchPayments from "./SearchPayments";
import ResendReceipts from "./ResendReceipts";
import {IPayment} from "../core/components/receipts/IPayment";

const Receipts = () => {

    const [paymentRecord, setPaymenRecord] = useState<IPayment>();
    const [searchTerm, setSearchTerm] = useState('');
    const payment = (paymentData: IPayment, searchTerm: string) => {
        setSearchTerm(searchTerm);
        setPaymenRecord(paymentData);

    };

    const switchToSearchView = () => {
        setPaymenRecord(undefined);
    };

    return(
        <div>
            {!paymentRecord && <SearchPayments onReceiptSelected={payment}
            searchTerm={searchTerm}/>}
            {paymentRecord && <ResendReceipts payment={paymentRecord}
                searchPayments={switchToSearchView}/>}
        </div>
    )
};

export default Receipts;